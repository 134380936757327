import requests from "./httpService";

const DestinationServices = {

    getDestination : async () =>{
        return requests.get(`/destination`);
    },
    getWebDestination : async () =>{
        return requests.get(`/destination/web`);
    },
    updateDestination : async (id,body) =>{
        return requests.patch(`/destination/${id}`,body);
    },

    createDestination : async (body) =>{
        console.log(body)
        return requests.post(`/destination`,body);
    },
    deleteDestination : async (id) =>{
        return requests.delete(`/destination/${id}`);
    }
    


}

export default DestinationServices;