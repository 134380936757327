import requests from "./httpService";

const ContactServices = {
    getContactDetail:async () =>{
        return requests.get(`/contact`);
    },
    updateContactDetail :async (id,body) =>{
        console.log(">>>",body)
        return requests.patch(`/contact/${id}`,body);
    },
    getPrivicyPolicy : async () =>{
        return requests.get(`/app?data=privacyPolicy`);
    },
    updateAppPolicy : async (body) =>{
        return requests.patch(`/app`,body);
    },

    getTermCondition : async () =>{
        return requests.get(`/app?data=termsAndCondition`);
    },

    getRefundPolicy : async () =>{
        return requests.get(`/app?data=refundPolicy`);
    },
    howToImage: async () =>{
        return requests.get(`/app/howtoimage`);
    },
    updateHowToImage : async (body) =>{
        return requests.patch(`/app/howtoimage`, body);
    }

}

export default ContactServices;