import React,{useState} from 'react';
import Pagetitle from './PageTitle';
function AppSettings() {

  
  const [formValues, setFormValues] = useState({
    title: '',
    message: '',
    image: '',
    link: '',
    userFor: '',
  });

  const [previewImage, setPreviewImage] = useState('img/placeholder-img.png'); // Placeholder image path


  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormValues({
        ...formValues,
        image: file,
      });
    }
  };
//   // Handle form submission
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       const formData = new FormData();
//       console.log(formData);
//     for (const key in formValues) {
//       formData.append(key, formValues[key]);
//     }
//       console.log(formValues)
//      const response = await NotificationServices.createNotification(formData);
//       // Assuming updateContactDetail is a method in UserServices
//  console.log(response);
//       alert('Notification Added Successfully');
//       } catch (error) {
//       console.error('Failed to notification details', error);
//       alert('Failed to add Notification details');
//     }
//   };

  return (
    <>
    <div className="right_col" role="main">
      <Pagetitle></Pagetitle>
        <div className="container-box">
          <div className="container-box-inner px-4">
          <h5>General Settings</h5>
            <div className="page-details mt-3">
              <form>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="input-field">
                      <label>App Name</label>
                      <input type="text" name="App Name" className="form-control"  value={formValues.title} 
                        onChange={handleInputChange}  placeholder="Enter App Name" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-field">
                      <label>App Description</label>
                      <input type="text" name="Description" className="form-control"  value={formValues.title} 
                        onChange={handleInputChange}  placeholder="Enter Description" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-field">
                      <label>Language</label>
                      <select className="form-control" value={formValues.sequence} 
                        onChange={handleInputChange} name="language">
                        <option value="English">English</option>
                        <option value="Hindi">Hindi</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-field">
                      <label>Upload Logo<span className="red">*</span></label>
                      <input type="file" name="logo" className="form-control" onChange={handleFileChange} />
                      <div className="file-preview">
                        <img id="uploadFile" src={previewImage} alt="yourimage" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-field">
                      <label>App Version</label>
                      <input type="text" name="App Version" className="form-control"  value={formValues.link} 
                        onChange={handleInputChange}  placeholder="App Version" />
                    </div>
                  </div>
                </div>
                <button className="site-btn-green">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AppSettings