import requests from "./httpService";
const AuthServices = {
    login: async (body) =>{
        console.log(">>>",body);
        return requests.post(`/auth/login`,body);
    },
    register: async (body) =>{
        return requests.post(`/auth/register`,body);
    },
    verifyLogin: async (body) =>{
        console.log("verify",body);
        return requests.post(`/auth/verify`,body);
    },
    verifyregister: async (body) =>{
        return requests.post(`/auth/verifysignup`,body);
    },
    resendOtp: async (body) =>{
        return requests.post(`/auth/resendOtp`,body);
    },
    updateMe: async (body) =>{
        console.log(">>>>",body)
        return requests.patch(`/user/updateMe/`,body);
    },
    getMyProfile: async (body) =>{
        // console.log(">>>>",body)
        return requests.get(`/user/myProfile/`,body);
    },

}

export default AuthServices;