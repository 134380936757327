import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import useAsync from '../hooks/useAsync';
import UserServices from '../services/UserServices';
import BookingServices from '../services/BookingServies';
import ViewUser from './View Model/viewBooking';
import Modal from 'react-modal';
import ReactPaginate from 'react-paginate';
// import UserStatus from './TogelButton/UserStatus';
import SelectStatus from './TogelButton/SelectBookingStatus';
import Pagetitle from './PageTitle';
import * as XLSX from 'xlsx';

Modal.setAppElement('#root');

function UserManager() {
    const { data, error, isLoading, run } = useAsync(BookingServices.getBooking);
    // console.log(">>>", data)
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');

    useEffect(() => {


        UserServices.getCity().then((response) => {
            setCities(response.data);
        }).catch((error) => {
            console.error('Error fetching cities:', error);
        });
    }, []);

    const handleViewDetails = (user) => {
        // console.log("Opening modal for user:", user);

        setSelectedUser(user);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedUser(null);
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();

        return `${day}-${month}-${year}`;
    };

    const usersPerPage = 10;
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;

    const filteredUsers = data?.data?.filter(user => {
        // Provide default values if user properties are undefined
        const city = user?.city || '';  // Corrected to use user?.user?.city
        const name = user?.user?.name || '';
        const mobileNo = user?.user?.mobileNo?.toString() || '';

        // Normalize values to lower case
        const normalizedCity = city.toLowerCase();
        const normalizedSearchQuery = searchQuery.toLowerCase();

        // Check if search query matches any of the fields
        const matchesSearchQuery = name.toLowerCase().includes(normalizedSearchQuery) ||
            normalizedCity.includes(normalizedSearchQuery) ||
            mobileNo.toLowerCase().includes(normalizedSearchQuery);

        // Check if city matches the selected city
        const matchesCity = selectedCity ? normalizedCity === selectedCity.toLowerCase() : true;

        return matchesSearchQuery && matchesCity;
    });


    const currentUsers = filteredUsers?.slice(indexOfFirstUser, indexOfLastUser);

    const generateExcel = () => {
        const filteredData = filteredUsers?.map(user => ({  
            userName: user?.user?.name,
            bookingId: user?.bookingId,
            userMobile: user?.user?.mobileNo,
            name: user?.contact?.name,
            mobileNo: user?.contact?.mobileNo,
            city: user?.contact?.city,
            date: user?.createdAt,
            packageName: user?.packageName,
            packageAmount: user?.totalAmount,
            paymentMethod: user?.paymentMethod,
            referralCode: user?.refer,
            status: user?.status
        }));

        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Users');

        XLSX.writeFile(wb, 'user_data.xlsx');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
        setCurrentPage(1);
    };

    return (
        <div className="right_col" role="main">
            <Pagetitle />
            <div className="container-box px-0 user-manager">
                <div className="container-box-top-header px-4">
                    <div className="container-box-top-header-left-2">
                        <input
                            type="search"
                            name="search"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <button className="search-btn"><FontAwesomeIcon icon={faSearch} /></button>
                    </div>
                    {/* <div>
                        <select className="dropdown" value={selectedCity} onChange={handleCityChange}>
                            <option value="">City</option>
                            {cities.map((city, index) => (
                                <option key={index} value={city}>{city}</option>
                            ))}
                        </select>
                    </div> */}
                    <div className="sub-title-box-right">
                        <button className="excel-btn site-btn-green" onClick={generateExcel}>
                            <img src="img/excel.svg" alt='' />
                            <span className='ms-2'>Download</span>
                        </button>
                    </div>
                </div>
                <div className="container-box-inner">
                    <table id="example" className="table table-striped" style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>BookingId</th>
                                <th>UserName</th>
                                <th>UserMobile </th>
                                <th>Name</th>
                                <th>Mobile No.</th>
                                <th>Tax</th>
                                <th> Date</th>
                                <th>Package Name</th>
                                <th>Amount</th>
                                <th>Payment </th>
                                <th>Status</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentUsers?.map((user, index) => (
                                <tr key={user?._id}>
                                    <td>{index + 1 + (currentPage - 1) * usersPerPage}</td>
                                    <td>{user?.bookingId}</td>
                                    <td>{user?.user?.name}</td>
                                    <td>{user?.user?.mobileNo}</td>
                                    <td>{user?.contact?.name}</td>
                                    <td>{user?.contact?.mobileNo}</td>
                                    <td>{user?.tax}</td>
                                    <td>{formatDate(user?.createdAt)}</td>
                                    <td>{user?.packageName}</td>
                                    <td>
                                        {user?.registrationFees > 0 ? user?.registrationFees : user?.totalAmount}
                                    </td>
                                    <td>{user?.paymentMethod}</td>
                                    <td className="status-toggle">
                                        {/* <UserStatus user={user} onSuccess={run} /> */}
                                        <SelectStatus data={user} page="Booking" onSuccess={run} />
                                    </td>
                                    <td>
                                        <button className="view-details-btn" onClick={() => handleViewDetails(user)}>
                                            <FontAwesomeIcon icon={faEllipsisV} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="pagination">
                    <ReactPaginate
                        pageCount={Math.ceil((filteredUsers?.length || 0) / usersPerPage)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        initialPage={currentPage - 1}
                        disableInitialCallback={true}
                        pageLinkClassName={'page-link'}
                        previousLinkClassName={'page-link'}
                        nextLinkClassName={'page-link'}
                        pageClassName={'page-item'}
                        previousClassName={'page-item'}
                        nextClassName={'page-item'}
                        disabledClassName={'disabled'}
                    />
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="User Details"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <ViewUser user={selectedUser} closeModal={closeModal} onSuccess={run} />
            </Modal>
        </div>
    );
}

export default UserManager;
