import requests from "./httpService";

const WishlistServices = {

    getWishlist : async () =>{
        return requests.get(`/wishlist`);
    },
    removeFromWishlist : async (body) =>{
        return requests.post(`/wishlist/remove/`,body);
    },

    addToWishlist : async (body) =>{
        // console.log(body)
        return requests.post(`/wishlist`,body);
    },
    deletewishlist : async (id) =>{
        return requests.delete(`/wishlist/${id}`);
    }
    , 
    sendPushwishlist : async (body) =>{
        console.log(body)
        return requests.post(`/wishlist/sendwishlist`,body)
    },
    getPushwishlist : async () =>{
        return requests.get(`/wishlist/push`);
    },
    deletePushwishlist : async (id) =>{
        return requests.delete(`/wishlist/push/${id}`);
    }


}

export default WishlistServices;