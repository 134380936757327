import React,{useState,useEffect} from 'react';
import ContactServices from '../services/ContactServices';
import useAsync from '../hooks/useAsync';
import Pagetitle from './PageTitle';
function Contact() {
  const {data , error, isLoading} = useAsync(ContactServices.getContactDetail);
  // console.log(data)

  const [formValues, setFormValues] = useState({
    phone: '',
    email: '',
    whatsApp: '',
    version:'',
  });

  // Update form values when data is fetched
  useEffect(() => {
    if (data.data) {
      setFormValues({
        email: data.data.email || '',
        phone: data.data.phone || '',
        whatsApp: data.data.whatsApp || '',
        version: data.data.version || '',
      });
    }
  }, [data]);

  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const id = data.data._id; 
      // console.log("Updating Contact ID:", id);
      // console.log("formValues:", formValues);
      await ContactServices.updateContactDetail(id, formValues);
      alert('Contact details updated successfully');
    } catch (error) {
      console.error('Failed to update contact details', error);
      alert('Failed to update contact details');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading contact details</div>;
  }

  return (
    <>
    <div className="right_col" role="main">
    <Pagetitle />
        {/* <div className="title-box">
          <h2> Support Contact </h2>
        </div> */}
        <div className="container-box px-5">
          <div className="container-box-inner">
            <div className="page-details">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="input-field">
                      <label>Mobile No.</label>
                      <input 
                        type="text" 
                        name="phone" 
                        maxLength={10}
                        className="form-control" 
                        value={formValues.phone} 
                        onChange={handleInputChange} 
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="input-field">
                      <label>Email</label>
                      <input 
                        type="email" 
                        name="email" 
                        className="form-control" 
                        value={formValues.email} 
                        onChange={handleInputChange} 
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="input-field">
                      <label>Whatsapp No.</label>
                      <input 
                        type="text" 
                        name="whatsApp" 
                        maxLength={10}
                        className="form-control" 
                        value={formValues.whatsApp} 
                        onChange={handleInputChange} 
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="input-field">
                      <label>Verison</label>
                      <input 
                        type="text" 
                        name="version" 
                        className="form-control" 
                        value={formValues.version} 
                        onChange={handleInputChange} 
                      />
                    </div>
                  </div>
                 
                </div>
                {/* <button type="submit" className="submit-green-btn">SUBMIT</button> */}
                <button className="site-btn-green">SUBMIT</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact