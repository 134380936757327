import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUser, faImage, faFileAlt, faBell, faCalendarAlt, faCube, faChevronDown, faChevronRight, faGear, fas, faShareAltSquare, faShapes, faShieldAlt, faContactCard, faPhone, faAddPackage, faMapLocation, faFileArrowUp  } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import './Css/sideBar.css';
import WinjaLogo from './Img/winja-logo.png';
import Package from './Img/package.png';
import Destination from './Img/destination.png';
import Vendor from './Img/vendor.png';
import Privacy from './Img/privacy.png';
import Refund from './Img/refund.png';

const Sidebar = ({ isOpen }) => {  // Accept isOpen prop
  const [pagesOpen, setPagesOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const togglePages = () => setPagesOpen(!pagesOpen);
  const toggleSettings = () => setSettingsOpen(!settingsOpen);

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}> {/* Apply classes based on isOpen */}
      <div className="logo-container">
        
        <NavLink to="/admin/dashboard" activeClassName="active">
          <img src={WinjaLogo} alt="Logo" className="logo" />
          </NavLink>
      </div>
      <ul className="sidebar-menu">
        <li className="sidebar-item">
          <NavLink to="/admin/dashboard" activeClassName="active">
          <FontAwesomeIcon icon={faShapes} />
         
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li className="sidebar-item">
          <NavLink to="/admin/user-manager" activeClassName="active">
            <FontAwesomeIcon icon={faUser} />
            <span>User Manager</span>
          </NavLink>
        </li>
        <li className="sidebar-item">
          <NavLink to="/admin/app-banner-list" activeClassName="active">
            <FontAwesomeIcon icon={faImage} />
            <span>App Banner</span>
          </NavLink>
        </li>
        <li className="sidebar-item">
          <NavLink to="/admin/privacy-policy" activeClassName="active">
            <FontAwesomeIcon icon={faShieldAlt} />
            {/* <img src={Privacy} alt="Logo"  /> */}
            <span>Privacy Policy</span>
          </NavLink>
        </li>
        <li className="sidebar-item">
          <NavLink to="/admin/term-and-condition" activeClassName="active">
            <FontAwesomeIcon icon={faShieldAlt} />
            <span>Terms & Conditions</span>
          </NavLink>
        </li>
        <li className="sidebar-item">
          <NavLink to="/admin/refund-policy" activeClassName="active">
            <FontAwesomeIcon icon={faShieldAlt} />
            {/* <img src={Refund} alt="Logo"  /> */}
            <span>Refund Policy</span>
          </NavLink>
        </li>
        <li className="sidebar-item">
          <NavLink to="/admin/contact" activeClassName="active">
            <FontAwesomeIcon icon={faPhone} />
            <span>Contact Us</span>
          </NavLink>
        </li>
         
          
       
        <li className="sidebar-item">
          <NavLink to="/admin/notification-list" activeClassName="active">
            <FontAwesomeIcon icon={faBell} />
            <span>Notification</span>
          </NavLink>
        </li>
        <li className="sidebar-item">
          <NavLink to="/admin/destination-list" activeClassName="active">
            <FontAwesomeIcon icon={faMapLocation} />
            <span> Destination</span>
          </NavLink>
        </li>
        <li className="sidebar-item">
          <NavLink to="/admin/package" activeClassName="active">
            <FontAwesomeIcon icon={faFileArrowUp} />
            <span>Package</span>
          </NavLink>
        </li>
        
        <li className="sidebar-item">
          <NavLink to="/admin/booking" activeClassName="active">
            <FontAwesomeIcon icon={faCube} />
            <span>All Booking List</span>
          </NavLink>
        </li>
        <li className="sidebar-item">
          <NavLink to="/admin/vendor-list" activeClassName="active">
            <FontAwesomeIcon icon={faUser} />
            <span>Vendor List</span>
          </NavLink>
        </li>
        <li className="sidebar-item">
          <NavLink to="/admin/profile" activeClassName="active">
            <FontAwesomeIcon icon={faGear} />
            <span>Settings</span>
          </NavLink>
        </li>
        {/* <li className={`sidebar-item has-sub-menus ${settingsOpen ? 'active' : ''}`} onClick={toggleSettings}>
          <FontAwesomeIcon icon={faGear} />
          <span>Settings</span>
          <FontAwesomeIcon icon={settingsOpen ? faChevronDown : faChevronRight} className="submenu-icon" />
        </li>
        {settingsOpen && (
          <ul className="submenu">
            <li className="submenu-item">
              <NavLink to="/profile" activeClassName="active">Profile</NavLink>
            </li>
            <li className="submenu-item">
              <NavLink to="/admin-user" activeClassName="active">Admin User</NavLink>
            </li>
            <li className="submenu-item">
              <NavLink to="/push-notification" activeClassName="active">Push Notification</NavLink>
            </li>
            <li className="submenu-item">
              <NavLink to="/email-notification" activeClassName="active">Email Notification</NavLink>
            </li>
            <li className="submenu-item">
              <NavLink to="/app-settings" activeClassName="active">App Settings</NavLink>
            </li>
          </ul>
        )} */}
      </ul>
    </div>
  );
};

export default Sidebar;
