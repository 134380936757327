import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import useAsync from '../hooks/useAsync';
import UserServices from '../services/UserServices';
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import { Link } from 'react-router-dom';
import UpdateAdmin from './Update Model/UpdateAdmin';
import Pagetitle from './PageTitle';

Modal.setAppElement('#root');

function AdminUser() {
  const { data, error, isLoading, run } = useAsync(UserServices.getAllAdmin);
  const [admins, setAdmins] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);

  useEffect(() => {
    if (data && data.status) {
      // console.log("Fetched data:", data);
      setAdmins(data.data);
    } else {
      console.error("Failed to fetch data or no data available:", error);
    }
  }, [data, error]);

  useEffect(() => {
    run();
  }, [run]);

  const handleEditDetails = (admin) => {
    setSelectedEdit(admin);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };

  const handleDelete = (admin) => {
    setSelectedEdit(admin);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    return date.toLocaleDateString();
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <div className="right_col" role="main">
    <Pagetitle></Pagetitle>
      <div className="container-box px-0 user-manager">
      <div className="container-box-top-header justify-content-end">
          <div className="sub-title-box-right">
          <Link className="Add-new-btn site-btn-green" to="/add-admin-user">
              <img src="img/plus-circle.svg" className='me-2' alt='' />Add New
            </Link>
          </div>
        </div>
        <div className="container-box-inner">
          {admins.length > 0 ? (
            <table id="example" className="table table-striped" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>User Type</th>
                  <th>Name</th>
                  <th>Mobile No.</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {admins.map((admin, index) => (
                  <tr key={admin._id}>
                    <td>{index + 1}</td>
                    <td><button class="statusBtnActive">{admin.userType}</button></td>
                    <td>{admin.name}</td>
                    <td>{admin.mobileNo}</td>
                    <td>{admin.email}</td>
                    <td>{formatDateTime(admin.createdAt)}</td>
                    <td>
                    <button className="view-details-btn edit-btn" onClick={() => handleEditDetails(admin)}><FontAwesomeIcon icon={faEdit} /></button>
                  </td>
                  <td>
                    <button className="view-details-btn delete-btn" onClick={() => handleDelete(admin)} ><FontAwesomeIcon icon={faTrash} /></button>
                  </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className='text-center'>No admins found.</div>
          )}
        </div>
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <UpdateAdmin admin={selectedEdit} closeModal={closeEditModal} onSuccess={run} />
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEdit} page="Admin" closeModal={closeDeleteModal} onSuccess={run} />
        </Modal>
      </div>
    </div>
  );
}

export default AdminUser;
