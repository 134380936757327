import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useAsync from '../../../hooks/useAsync';
import ContactServices from '../../../services/ContactServices';

function Footer() {
  const location = useLocation();
  const { data, run, error, isLoading } = useAsync(ContactServices.getContactDetail);
  // Function to determine if a link is active
  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  // Scroll to the top of the page on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <footer>
      <div className="container">
        <div className="footer-inner">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-icon-outer">
                <div className="footer-logo">
                  <a href="#">
                    <img src="/img/logo.png" alt="footer-logo"
                      style={{ maxWidth: '25%', height: 'auto', display: 'block', marginInline: 'auto' }}
                    />
                  </a>
                </div>
                <div className="footer-content">
                  <p className="footer-descripation">
                    {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since.
                    Our Services */}
                    We offer personalized travel packages for domestic and international trips, along with hassle-free hotel and transport bookings. From romantic honeymoon getaways to corporate travel solutions and group or family trips, we cater to all your travel needs.
                    {/* ► Custom Travel Packages: Tailored to your needs for both domestic and international travel.
                    ► Hotel & Transport Bookings: Secure and convenient bookings for hotels, cabs, and more.
                    ► Honeymoon Packages: Romantic getaways designed to make your special moments unforgettable.
                    ► Corporate Travel: Comprehensive travel solutions for businesses.
                    ► Group Travel & Family Packages: Perfectly planned trips for families, friends, and groups. */}
                  </p>
                </div>
                <div className="footer-content social-links mb-4">
                  <ul className="d-flex list-unstyled">
                    <li><a href="https://www.facebook.com/profile.php?id=61570452310621" target='_blank'><i className="fa fa-facebook" /></a></li>
                    <li><a href="https://www.instagram.com/" target='_blank'><i className="fa fa-instagram" /></a></li>
                    <li><a href="https://www.x.com/" target='_blank'><i className="fa fa-twitter" /></a></li>
                    <li><a href="https://www.pinterest.com/" target='_blank'><i className="fa fa-pinterest" /></a></li>
                    <li><a href="https://www.youtube.com/" target='_blank'><i className="fa fa-youtube-play" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="center-details details-2">
                <h3 className="center-details-heading">Contact</h3>
                <div className="contact-details">
                  <ul className="list-unstyled">
                  <li>
                      <span><i className="fa fa-phone" /></span>
                      <a href="tel:+917357501163 ">+91 7357501163</a>
                    </li>
                    <li>
                      <span><i className="fa fa-phone" /></span>
                      <a href={`tel:${data?.data?.phone}`}>+91 {data?.data?.phone}</a>
                    </li>
                    <li>
                      <span><i className="fa fa-envelope" /></span>
                      <a href={`mailto:${data?.data?.email}`}>{data?.data?.email}</a>
                    </li>
                    <li>
                      <span><i className="fa fa-whatsapp" /></span>
                      <a href={`https://wa.me/${data?.data?.whatsApp}`}>+91{data?.data?.whatsApp}</a>
                    </li>
                    <li>
                      <span><i className="fa fa-map-marker" /></span>
                      <a
                        href="https://www.google.com/maps/search/?api=1&query=B-FF-01,+R.+TECH+INFRA+CAPITAL+GALLERIA+LLP,+Manu+Marg,+Nearby+Landmark+Reliance+Smart,+Kabir+Colony,+Alwar,+Rajasthan+301001"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        B-FF-01, R. TECH INFRA CAPITAL GALLERIA LLP, Manu Marg, Nearby Landmark Reliance Smart, Kabir Colony, Alwar, Rajasthan 301001
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-quicklinks">
                <h2>Links</h2>
                <div className="quicklinks-contant">
                  <ul className="list-unstyled">
                    <li className={isActive('/')}>
                      <Link to="/">Home</Link>
                    </li>
                    <li className={isActive('/order')}>
                      <Link to="/order">Order</Link>
                    </li>
                    <li className={isActive('/privacy')}>
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                    {/* <li className={isActive('/booking')}>
                      <Link to="/booking">Booking</Link>
                    </li> */}
                    <li className={isActive('/contactUs')}>
                      <Link to="/contactUs">Contact us</Link>
                    </li>
                    <li className={isActive('/tour-package')}>
                      <Link to="/tour-package">Packages</Link>
                    </li>
                    <li className={isActive('/terms')}>
                      <Link to="/terms">Terms & Conditions</Link>
                    </li>
                    <li className={isActive('/notification')}>
                      <Link to="/notification">Notification</Link>
                    </li>
                    <li className={isActive('/wishlist')}>
                      <Link to="/wishlist">Wishlist</Link>
                    </li>
                    <li className={isActive('/refund')}>
                      <Link to="/refund">Refund Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-border"></div>
      <div className="development-company-name">
        <h3>© 2024 Winja Travel Made by <a href="https://truevalueinfosoft.com/" style={{color:"#fff"}}>True Value Infosoft </a> (P) Limited</h3>

      </div>
    </footer>
  );
}

export default Footer;
