import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import useAsync from '../hooks/useAsync';
import UserServices from '../services/UserServices';
import ViewUser from './View Model/ViewUser';
import Modal from 'react-modal';
import ReactPaginate from 'react-paginate';
import UserStatus from './TogelButton/UserStatus';
import Pagetitle from './PageTitle';
import * as XLSX from 'xlsx';

Modal.setAppElement('#root');

function UserManager() {
  const { data, error, isLoading, run } = useAsync(UserServices.getAllUser);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {


    UserServices.getCity().then((response) => {
      setCities(response.data);
    }).catch((error) => {
      console.error('Error fetching cities:', error);
    });
  }, []);

  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };

  const usersPerPage = 10;
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const filteredUsers = data?.data?.filter(user => {
    const normalizedCity = user?.city.toLowerCase();
    const normalizedSearchQuery = searchQuery.toLowerCase();
    const matchesSearchQuery = user?.name.toLowerCase().includes(normalizedSearchQuery) ||
                               normalizedCity.includes(normalizedSearchQuery) ||
                               user?.mobileNo.toString().toLowerCase().includes(normalizedSearchQuery);

    const matchesCity = selectedCity ? normalizedCity === selectedCity.toLowerCase() : true;

    return matchesSearchQuery && matchesCity;
  });

  const currentUsers = filteredUsers?.slice(indexOfFirstUser, indexOfLastUser);

  const generateExcel = () => {
    const filteredData = filteredUsers?.map(user => ({
      name: user?.name,
      image: process.env.REACT_APP_URL + user?.image,
      mobileNo: user?.mobileNo,
      city: user?.city,
      registerdate: formatDate(user?.createdAt),
      status: user?.status
    }));

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users');

    XLSX.writeFile(wb, 'user_data.xlsx');
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
    setCurrentPage(1);
  };

  return (
    <div className="right_col" role="main">
      <Pagetitle />
      <div className="container-box px-0 user-manager">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input
              type="search"
              name="search"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <button className="search-btn"><FontAwesomeIcon icon={faSearch} /></button>
          </div>
          <div>
            <select className="dropdown" value={selectedCity} onChange={handleCityChange}>
              <option value="">City</option>
              {cities.map((city, index) => (
                <option key={index} value={city}>{city}</option>
              ))}
            </select>
          </div>
          <div className="sub-title-box-right">
            <button className="excel-btn site-btn-green" onClick={generateExcel}>
              <img src="img/excel.svg" alt='' />
              <span className='ms-2'>Download</span>
            </button>
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Image</th>
                <th>Name</th>
                <th>Mobile No.</th>
                <th>Email</th>
                <th>City</th>
                <th>Reg. Date</th>
                <th>Status</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers?.map((user, index) => (
                <tr key={user?._id}>
                  <td>{index + 1 + (currentPage - 1) * usersPerPage}</td>
                  <td><img src={user?.image ? `${process.env.REACT_APP_URL}${user.image}` : 'img/profile-img.png'} alt="" style={{ height: '50px', width: '50px', objectFit: 'contain' }} /></td>
                  <td>{user?.name}</td>
                  <td>{user?.mobileNo}</td>
                  <td>{user?.email}</td>
                  <td>{user?.city}</td>
                  <td>{formatDate(user?.createdAt)}</td>
                  <td className="status-toggle"><UserStatus user={user} onSuccess={run} /></td>
                  <td>
                    <button className="view-details-btn" onClick={() => handleViewDetails(user)}>
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <ReactPaginate
            pageCount={Math.ceil((filteredUsers?.length || 0) / usersPerPage)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            initialPage={currentPage - 1}
            disableInitialCallback={true}
            pageLinkClassName={'page-link'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            disabledClassName={'disabled'}
          />
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="User Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ViewUser user={selectedUser} closeModal={closeModal} />
      </Modal>
    </div>
  );
}

export default UserManager;
