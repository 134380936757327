import React, { useState } from 'react';
import UserServices from '../../services/UserServices';

const UserStatus = ({ user, page, onSuccess }) => {
  // Initialize the state based on the user's status
  const [isChecked, setIsChecked] = useState(user?.status === 'Active');

  // Handle checkbox toggle
  const handleToggle = async () => {
    const newStatus = isChecked ? 'Inactive' : 'Active';  // Correct the status toggle
    setIsChecked(!isChecked);  // Update the checkbox state

    if (page === 'user') {
      try {
        const res = await UserServices.updateUser(user._id, {
          status: newStatus
        });

        if (res.status === 200) { // Check if the response is successful
          onSuccess();
        } else {
          console.error('Failed to update user status');
        }
      } catch (error) {
        console.error('An error occurred while updating user status', error);
      }
    }
  };

  return (
    <div className="check-box">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleToggle}
      />
    </div>
  );
};

export default UserStatus;
