import requests from "./httpService";

const UserServices = {

    getAllUser : async () =>{
   
        return requests.get(`/user`);
    },
    getAllVendor : async () =>{
       
        return requests.get(`/user?userType=Vendor`);
    },
    updateUser : async (id,body) =>{
        return requests.patch(`/user/${id}`,body);
    },
    updateVendor : async (id,body) =>{
        return requests.patch(`/user/vendor/${id}`,body);
    },
    getMyProfile : async () =>{
        return requests.get(`/userapp/profile`);
    },
    updateMe : async (body) =>{
        return requests.patch(`/userapp/updateMe`,body);
    },
    createAdmin : async (body) =>{
        console.log(body)
        return requests.post(`/userapp/`,body);
    },
    updatePassword : async (body) =>{
        return requests.patch(`/userapp/change-password`,body);
    },
    dashboard : async () =>{
        return requests.get(`/userapp/dashboard`);
    },
    getAllAdmin : async () =>{
        return requests.get(`/userapp`);
    },
    
    
   getCity: async () =>{
    return requests.get(`/user/city`);
  },
    

   


}

export default UserServices;
