import React, { useState, useEffect } from 'react';
import Pagetitle from './PageTitle';
import useAsync from '../hooks/useAsync';
import ContactServices from '../services/ContactServices';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import React Quill styles

function RefundPolicy() {
  const { data, error, isLoading } = useAsync(ContactServices.getRefundPolicy);
  console.log(">>>", data);
  
  const [formValues, setFormValues] = useState({
    refundPolicy: '',
    refundPolicyTitle: '',
    data: "refundPolicy"
  });

  useEffect(() => {
    if (data) {
      setFormValues({
        refundPolicyTitle: data?.data?.Title || '',
        refundPolicy: data?.data?.English || '',
        data: "refundPolicy"
      });
    }
  }, [data]);

  const handleInputChange = (name, value) => {
    setFormValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await ContactServices.updateAppPolicy(formValues);
      console.log(res);
      alert('Refund Policy updated successfully!');
    } catch (error) {
      console.error('Failed to update Refund Policy:', error);
      alert('Failed to update Refund Policy. Please try again.');
    }
  };

  return (
    <div className="right_col" role="main">
      <Pagetitle />
      <div className="container-box p-4 profile-container">
        <div className="container-box-inner">
          <div className="row page-details">
            <form onSubmit={handleSubmit}>
              <div className="input-field">
                <label>Title</label>
                <input
                  type="text"
                  name="title"
                  value={formValues.refundPolicyTitle}
                  onChange={(e) => handleInputChange('refundPolicyTitle', e.target.value)}
                  placeholder="Enter Title"
                  className="form-control"
                />
              </div>
          
              <div className="input-field">
                <label>Descriptions</label>
                <ReactQuill
                  theme="snow"
                  value={formValues.refundPolicy}
                  onChange={(value) => handleInputChange('refundPolicy', value)}
                />
              </div>
              <button className="site-btn-green">SUBMIT</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefundPolicy;
