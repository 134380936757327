import React, { useState, useEffect } from 'react';
import PackageServices from '../../services/PackageServices';
function UpdateDay({ pkg, closeModal, onSuccess }) {
    const initialSchedule = {
        day: '',
        date: '',
        location: '',
        activities: '',
        hotel: {
            hotelImages: [],
            name: '',
            location: '',
            address: '',
            roomType: '',
            meal: '',
            checkIn: '',
            checkOut: ''
        },
        transport: {
            from: '',
            to: '',
            type: '',
            vehicle: '',
            description: '',
            guide:''
        }
    };
    const [previewImages, setPreviewImages] = useState([]);
    const [formValues, setFormValues] = useState({ schedule: [initialSchedule] });
    const [uploading, setUploading] = useState(false);
    useEffect(() => {
        if (pkg.schedule) {
            setFormValues({
                schedule: pkg.schedule.map(day => ({
                    dayId: day._id || '',
                    day: day.day || '',
                    date: day.date ? day.date.split('T')[0] : '',
                    location: day.location || '',
                    activities: day.activities.join('\n') || '',
                    hotel: {
                        hotelImages: day.hotel.hotelImages || [],
                        name: day.hotel.name || '',
                        location: day.hotel.location || '',
                        address: day.hotel.address || '',
                        roomType: day.hotel.roomType || '',
                        meal: day.hotel.meal.join('\n') || '',
                        checkIn: day.hotel.checkIn || '',
                        checkOut: day.hotel.checkOut || ''
                    },
                    transport: {
                        from: day.transport.from || '',
                        to: day.transport.to || '',
                        type: day.transport.type || '',
                        vehicle: day.transport.vehicle || '',
                        description: day.transport.description || '',
                        guide:day.transport.guide || ''
                    }
                }))
            });
        }
    }, [pkg]);
    const handleInputChange = (e, index, field) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => {
            const updatedSchedule = [...prevValues.schedule];
            if (field === 'hotel' || field === 'transport') {
                updatedSchedule[index] = {
                    ...updatedSchedule[index],
                    [field]: {
                        ...updatedSchedule[index][field], // Ensure the nested object is spread
                        [name]: value
                    }
                };
            } else {
                updatedSchedule[index] = {
                    ...updatedSchedule[index],
                    [name]: value
                };
            }
            return { ...prevValues, schedule: updatedSchedule };
        });
    };
    const handleAddSchedule = () => {
        setFormValues(prevValues => ({
            schedule: [...prevValues.schedule, initialSchedule]
        }));
    };
    const handleFileChange = async (event, index) => {
        const files = Array.from(event.target.files);
        setUploading(true);
        try {
            const uploadedImages = await Promise.all(
                files.map(async (file) => {
                    const formData = new FormData();
                    formData.append('image', file);
                    const response = await PackageServices.uploadImage(formData);
                    return response.hotelImages;
                })
            );
            const flatUploadedImages = uploadedImages.flat();
            setFormValues((prevState) => {
                const updatedSchedule = prevState.schedule.map((item, i) => {
                    if (i === index) {
                        return {
                            ...item,
                            hotel: {
                                ...item.hotel,
                                hotelImages: [...item.hotel.hotelImages, ...flatUploadedImages], // Append the new image URLs
                            },
                        };
                    }
                    return item; // Return other items as is
                });
                return { schedule: updatedSchedule };
            });
        } catch (error) {
            console.error('Image upload failed', error); // Log the error if image upload fails
            alert('Failed to upload images');
        } finally {
            setUploading(false); // Reset the uploading state
        }
    };
    const handleRemoveImage = async (index, imageUrl) => {
        try {
            const response = await PackageServices.removeImage({ url: imageUrl });
            if (response.status === true) {
                setFormValues(prevState => {
                    const updatedSchedule = prevState.schedule.map((item, i) => {
                        if (i === index) {
                            return {
                                ...item,
                                hotel: {
                                    ...item.hotel,
                                    hotelImages: item.hotel.hotelImages.filter(image => image !== imageUrl)
                                }
                            };
                        }
                        return item;
                    });
                    return { ...prevState, schedule: updatedSchedule };
                });
                alert("Image removed successfully!");
            } else {
                alert("Failed to remove image from server");
            }
        } catch (error) {
            console.error('Failed to remove image', error);
            alert('Failed to remove image');
        }
    };
    const handleRemoveSchedule = async (index) => {
        const dayId = formValues.schedule[index].dayId;
        const packageId = pkg._id;
        try {
            const response = await PackageServices.removeDayToSchedule(packageId, dayId, {});
            // console.log(response)
            if (response.status === 200 || response.success) { // Updated condition
                setFormValues(prevValues => ({
                    schedule: prevValues.schedule.filter((_, i) => i !== index)
                }));
                alert("Schedule removed successfully!");
            } else {
                alert("Failed to remove the schedule. Please try again.");
            }
        } catch (error) {
            console.error("Error removing schedule:", error);
            alert("An error occurred while removing the schedule.");
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const payload = formValues.schedule.map((item) => ({
                ...item,
                activities: item.activities.split('\n'), // Convert activities to array
                hotel: {
                    ...item.hotel,
                    meal: item.hotel.meal.split('\n') // Convert meal to array
                }
            }));

            const promises = payload.map(async (day) => {
                const response = await PackageServices.updateDayToSchedule(pkg._id, day.dayId, day);
                if (response.status !== 200 && !response.success) {
                    throw new Error(`Failed to update day ${day.day}`);
                }
                return response;
            });

            await Promise.all(promises);
            alert('Schedule updated successfully');
            onSuccess();  // Trigger success callback
            closeModal(); // Close modal
        } catch (error) {
            console.error('Failed to update schedule:', error);
            alert('Failed to update schedule');
        }
    };
    return (
        <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit Day to Package</h5>
                        <button type="button" className="btn-close" onClick={closeModal} aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="container-box-inner">
                            <div className="page-details">
                                <form onSubmit={handleSubmit}>
                                    {formValues.schedule.map((item, index) => (
                                        <div key={index} className="schedule-section">
                                            <h6>Day {index + 1}</h6>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="input-field">
                                                        <label>Day</label>
                                                        <input type="number" name="day" placeholder='1' value={item.day} onChange={(e) => handleInputChange(e, index, 'schedule')} className="form-control" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-field">
                                                        <label>Date</label>
                                                        <input type="date" name="date" placeholder='YYYY-MM-DD' value={item.date} onChange={(e) => handleInputChange(e, index, 'schedule')} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-field">
                                                        <label>Location</label>
                                                        <input type="text" name="location" placeholder='Shimala' value={item.location} onChange={(e) => handleInputChange(e, index, 'schedule')} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="input-field">
                                                        <label>Activities</label>
                                                        <textarea name="activities" placeholder='Riding,skipper' value={item.activities} onChange={(e) => handleInputChange(e, index, 'schedule')} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <h6>Hotel Details</h6>
                                                </div>
                                                {['name', 'location', 'address', 'roomType', 'meal', 'checkIn', 'checkOut'].map((field) => (
                                                    <div className="col-md-4" key={field}>
                                                        <div className="input-field">
                                                            <label>{field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}</label>
                                                            <input type={field.includes('date') ? 'date' : 'text'} name={field} value={item.hotel[field]} onChange={(e) => handleInputChange(e, index, 'hotel')} className="form-control" required
                                                                placeholder={item.hotel[field] || `Enter ${field.charAt(0).toUpperCase() + field.slice(1)}`}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="col-sm-6">
                                                    <div className="input-field">
                                                        <label>Upload Images</label>
                                                        <input type="file" name="hotel.hotelImages" className="form-control" multiple onChange={(e) => handleFileChange(e, index)} />
                                                        <p><span>Supported formats: png, jpeg, jpg, webp</span></p>
                                                        <div className="file-preview text-center">
                                                            {item.hotel.hotelImages.length > 0 && item.hotel.hotelImages.map((imageUrl, idx) => (
                                                                <div key={idx} style={{ position: 'relative', display: 'inline-block' }}>
                                                                    <img src={`${process.env.REACT_APP_URL}${imageUrl}`} alt={`preview ${idx}`} style={{ maxWidth: '100px', margin: '5px' }} />
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => handleRemoveImage(index, imageUrl)}
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '0',
                                                                            right: '0',
                                                                            background: 'red',
                                                                            color: 'white',
                                                                            border: 'none',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                    >
                                                                        X
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <h6>Transport Details</h6>
                                                </div>
                                                {['from', 'to', 'type', 'vehicle', 'description','guide'].map((field) => (
                                                    <div className="col-md-4" key={field}>
                                                        <div className="input-field">
                                                            <label>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                                                            <input type="text" name={field} value={item.transport[field]} onChange={(e) => handleInputChange(e, index, 'transport')} className="form-control" 
                                                                placeholder={item.transport[field] || `Enter ${field.charAt(0).toUpperCase() + field.slice(1)}`}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <button type="button" onClick={() => handleRemoveSchedule(index)} className="btn btn-danger">Remove Schedule</button>
                                        </div>
                                    ))}
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UpdateDay;
