import React from 'react'
import RequestServies from '../../services/RequestServices'
import NotificationServices from '../../services/NotificationServices';
import HomesliderServices from '../../services/HomeSliderServices';
import UserServices from '../../services/UserServices';
// import FeedbackServices from '../../services/FeedbackServices';
import PetServices from '../../services/PetServices';
import ProductServices from '../../services/ProductServices';
import FaqServices from '../../services/FaqServices';
import EducationServices from '../../services/EducationServices';
import AppointmentServices from '../../services/AppointmentServices';

import DestinationServices from '../../services/DestinationServices';
import PackageServices from '../../services/PackageServices';



function DeleteButton({data,page,closeModal,onSuccess}) {


  const handleDelete = async () => {
  if(page === 'pet'){
    const res = await PetServices.deletePet(data._id);
    
    if(res.status === true){
      alert('deleted successfully');
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  
  }else if (page === 'Help') {

    const res = await RequestServies.deleteHelp(data._id);

    if(res.status === true){
      alert('deleted successfully');
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }
  else if (page === 'Package') {

    const res = await PackageServices.deletePackage(data._id);

    if(res.status === true){
      alert('deleted successfully');
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }
  else if (page === 'sellAnimal') {

    const res = await PetServices.deleteSellAnimal(data._id);

    if(res.status === true){
      alert('deleted successfully');
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }
  // else if (page === 'feedback') {

  //   const res = await FeedbackServices.deleteFeedback(data._id);

  //   if(res.status === true){
  //     alert('deleted successfully');
  //     closeModal();
  //     onSuccess();
  //   } 
  //   else {
  //     console.log(res)
  //   }
  // }
  else if (page === 'Add') {

    const res = await RequestServies.deleteAdevertis(data._id);

    if(res.status === true){
      alert('deleted successfully');
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }else if (page === 'HomeSlider') {

    const res = await HomesliderServices.deleteHomeSlider(data._id);

    if(res.status === true){
      alert('deleted successfully');
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }else if (page === 'notification') {

    const res = await NotificationServices.deleteNotification(data._id);

    if(res.status === true){
      alert('deleted successfully');
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }else if (page === 'destination') {

    const res = await DestinationServices.deleteDestination(data._id);

    if(res.status === true){
      alert('deleted successfully');
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }
 
  else if (page === 'Admin') {

    const res = await UserServices.deleteAdmin(data._id);

    if(res.status === true){
      alert('deleted successfully');
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }else if (page === 'product') {

    const res = await ProductServices.deleteProduct(data._id);

    if(res.status === true){
      alert('deleted successfully');
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }
  else if (page === 'category') {

    const res = await ProductServices.deleteCategory(data._id);

    if(res.status === true){
      alert('deleted successfully');
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }
  else if (page === 'faq') {

    const res = await FaqServices.deleteFaq(data._id);

    if(res.status === true){
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }
  else if (page === 'faqCategory') {

    const res = await FaqServices.deleteFaqCategory(data._id);

    if(res.status === true){
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }
  else if (page === 'apointment') {

    const res = await AppointmentServices.deleteApointment(data._id);

    if(res.status === true){
      alert('deleted successfully');
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }
  else if (page === 'education') {

    const res = await EducationServices.deleteEducation(data._id);
    console.log('>>>',res)

    if(res.status === true){
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }
  else if (page === 'Banner') {

    const res = await HomesliderServices.deleteBanner(data._id);

    if(res.status === true){
      alert('deleted successfully');
      closeModal();
      onSuccess();
    } 
    else {
      console.log(res)
    }
  }
  }


  return (
    <div className="modal fade show d-block" id="deleteACModel" tabIndex="-1" aria-labelledby="deleteACModelLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content modal-bg-light-green">
          <div className="modal-body py-4 px-5 text-center">
              <h4>Delete this Item?</h4>
              <p className="desc my-2">Are you sure you want to delete this Item? This action cannot be undone.</p>
              <div className="footer-btn-grp text-center py-4">
                  <button className="site-btn btn-o" onClick={closeModal}>Cancel</button>
                  <button className="site-btn btn-red delete-btn" onClick={handleDelete}>Delete </button>
              </div>
          </div>
      </div>
    </div>
  </div>
  )
}

export default DeleteButton