import React, { useState, useEffect } from 'react';
import Pagetitle from './PageTitle';
import useAsync from '../hooks/useAsync';
import ContactServices from '../services/ContactServices';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import React Quill styles

function TermCondition() {
  const { data, error, isLoading } = useAsync(ContactServices.getTermCondition);
  console.log(">>>", data);
  
  const [formValues, setFormValues] = useState({
    termsAndCondition: '',
    termsAndConditionTitle: '',
    data: "termsAndCondition"
  });

  useEffect(() => {
    if (data) {
      setFormValues({
        termsAndConditionTitle: data?.data?.Title || '',
        termsAndCondition: data?.data?.English || '',
        // privacyPolicyTitle: data?.data?.privacyPolicyTitle || '', 
        data: "termsAndCondition"
      });
    }
  }, [data]);

  const handleInputChange = (name, value) => {
    setFormValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await ContactServices.updateAppPolicy(formValues);
      console.log(res);
      alert('Terms and Conditions updated successfully!');
    } catch (error) {
      console.error('Failed to update Terms and Conditions:', error);
      alert('Failed to update Terms and Conditions. Please try again.');
    }
  };

  return (
    <div className="right_col" role="main">
      <Pagetitle />
      <div className="container-box p-4 profile-container">
        <div className="container-box-inner">
          <div className="row page-details">
            <form onSubmit={handleSubmit}>
              <div className="input-field">
                <label>Title</label>
                <input
                  type="text"
                  name="title"
                  value={formValues.termsAndConditionTitle}
                  onChange={(e) => handleInputChange('termsAndConditionTitle', e.target.value)}
                  placeholder="Enter Title"
                  className="form-control"
                />
              </div>
          
              <div className="input-field">
                <label>Descriptions</label>
                <ReactQuill
                  theme="snow"
                  value={formValues.termsAndCondition}
                  onChange={(value) => handleInputChange('termsAndCondition', value)}
                />
              </div>
              <button className="site-btn-green">SUBMIT</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermCondition;



// import React, { useState, useEffect } from 'react';
// import Pagetitle from './PageTitle';
// import useAsync from '../hooks/useAsync';
// import ContactServices from '../services/ContactServices';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css'; // Import React Quill styles
// function TermCondition() {
//   // const { data, error, isLoading } = useAsync(ContactServices.getPrivicyPolicy);
//   const [formValues, setFormValues] = useState({ termCondition: '', termConditionHindi: '', data: "termCondition" });
//   // useEffect(() => {
//   //   if (data) {
//   //     setFormValues({
//   //       termCondition: data?.data?.English || '',
//   //       termConditionHindi: data?.data?.Hindi || '',
//   //       data: "termCondition"
//   //     });
//   //   }
//   // }, [data]);
//   const handleInputChange = (name, value) => {
//     setFormValues(prevValues => ({ ...prevValues, [name]: value }));
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const res = await ContactServices.updateAppPolicy(formValues);
//       console.log(res);
//       alert('Privacy Policy updated successfully!');
//     } catch (error) {
//       console.error('Failed to update Privacy Policy:', error);
//       alert('Failed to update Privacy Policy. Please try again.');
//     }
//   };
//   // if (isLoading) {
//   //   return <div>Loading...</div>;
//   // }
//   // if (error) {
//   //   return <div>Error: {error.message}</div>;
//   // }
//   return (
//     <div className="right_col" role="main">
//       <Pagetitle></Pagetitle>
//       <div className="container-box p-4 profile-container">
//         <div className="container-box-inner">
//           <div className="row page-details">
//             <form onSubmit={handleSubmit}>
//             <div className="input-field">
//                   <label>Tittle</label>
//                   <input type="text" name="title" value={formValues.title} 
//                         onChange={handleInputChange} placeholder='Enter Title' className="form-control" />
//                 </div>
//               <div className="input-field">
//                 <label>Description (English)</label>
//                 <ReactQuill
//                   theme="snow"
//                   value={formValues.termCondition}
//                   onChange={(value) => handleInputChange('termCondition', value)}
//                 />
//               </div>
//               <div className="input-field mt-3">
//                 <label>Description (Hindi)</label>
//                 <ReactQuill
//                   theme="snow"
//                   value={formValues.termConditionHindi}
//                   onChange={(value) => handleInputChange('termConditionHindi', value)}
//                 />
//               </div>
//               <button className="site-btn-green">SUBMIT</button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
// export default TermCondition;