import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HomeSliderServices from '../../services/HomeSliderServices';
import Pagetitle from '../../component/PageTitle';

function AddBanner() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    title: '',
    link: '',
    sequence: '1',
    image: '',
    time: '',
    startDate: '',
    endDate: '',
    status: 'show',
  });

  const [previewImage, setPreviewImage] = useState('img/placeholder-img.png'); // Placeholder image path

  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormValues({
        ...formValues,
        image: file,
      });
    }
  };

  
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const formData = new FormData();
  
      // Append form values to formData, including the image file
      formData.append('title', formValues.title);
      formData.append('link', formValues.link);
      formData.append('sequence', formValues.sequence);
      formData.append('time', formValues.time);
      formData.append('startDate', formValues.startDate);
      formData.append('endDate', formValues.endDate);
      formData.append('status', formValues.status);
  
      // Append the image file
      if (formValues.image) {
        formData.append('image', formValues.image);
      }
  
      // Submit the form data
      const response = await HomeSliderServices.createBanner(formData); // Pass formData
      // console.log(response);
      alert('Offer Banner Added Successfully');
      navigate('/admin/app-banner-list');

    } catch (error) {
      console.error('Failed to Offer Banner details', error);
      alert('Failed to add Offer banner details');
    }
  };
  
  


  return (
    <>
      <div className="right_col" role="main">
        <Pagetitle />
        <div className="container-box">
          <div className="container-box-top-header justify-content-end">
            <div className="sub-title-box-right">
              <Link className="site-btn-green" to="/admin/app-banner-list">List Banner</Link>
            </div>
          </div>
          <div className="container-box-inner px-4">
            <div className="page-details">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="input-field">
                      <label>Title*</label>
                      <input
                        type="text"
                        name="title"
                        value={formValues.title}
                        onChange={handleInputChange}
                        placeholder="Enter Title"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-field">
                      <label>Select Sequence</label>
                      <select
                        className="form-control"
                        value={formValues.sequence}
                        onChange={handleInputChange}
                        name="sequence"
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-field">
                      <label>Banner Status*</label>
                      <select
                        className="form-control"
                        value={formValues.status}
                        onChange={handleInputChange}
                        name="status"
                      >
                        <option value="show">Active</option>
                        <option value="hide">Disable</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-field">
                      <label>Offer Time</label>
                      <input
                        type="text"
                        name="time"
                        value={formValues.time}
                        onChange={handleInputChange}
                        placeholder="e.g., 24 hours"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="input-field">
                      <label>Redirect URL</label>
                      <div className="input-link-outer">
                        <input
                          type="text"
                          name="link"
                          value={formValues.link}
                          onChange={handleInputChange}
                          className="form-control"
                          placeholder="Redirect URL"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="input-field">
                      <label>Upload* <small>(Size should be 343 x 160)</small></label>
                      <input
                        type="file"
                        name="image"
                        className="form-control"
                        onChange={handleFileChange}
                        required
                      />
                      <div className="file-preview">
                        <img id="uploadFile" src={previewImage} alt="Preview" />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="input-field">
                      <label>Start Date*</label>
                      <input
                        type="date"
                        name="startDate"
                        value={formValues.startDate}
                        onChange={handleInputChange}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="input-field">
                      <label>End Date*</label>
                      <input
                        type="date"
                        name="endDate"
                        value={formValues.endDate}
                        onChange={handleInputChange}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
                <button className="site-btn-green">SUBMIT</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddBanner;
