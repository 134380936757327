import React,{useState} from 'react';
import Pagetitle from './PageTitle';
function EmailNotification() {

  
  const [formValues, setFormValues] = useState({
    title: '',
    message: '',
    image: '',
    link: '',
    userFor: '',
  });

  const [previewImage, setPreviewImage] = useState('img/placeholder-img.png'); // Placeholder image path


  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormValues({
        ...formValues,
        image: file,
      });
    }
  };
//   // Handle form submission
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       const formData = new FormData();
//       console.log(formData);
//     for (const key in formValues) {
//       formData.append(key, formValues[key]);
//     }
//       console.log(formValues)
//      const response = await NotificationServices.createNotification(formData);
//       // Assuming updateContactDetail is a method in UserServices
//  console.log(response);
//       alert('Notification Added Successfully');
//       } catch (error) {
//       console.error('Failed to notification details', error);
//       alert('Failed to add Notification details');
//     }
//   };

  return (
    <>
    <div className="right_col" role="main">
      <Pagetitle></Pagetitle>
        <div className="container-box">
          <div className="container-box-inner px-4">
            <div className="page-details">
              <form>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="input-field">
                      <label>From Email</label>
                      <input type="text" name="From Email" className="form-control"  value={formValues.title} 
                        onChange={handleInputChange}  placeholder="Enter Email" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-field">
                      <label>From Email Name</label>
                      <input type="text" name="From Email Name" className="form-control"  value={formValues.title} 
                        onChange={handleInputChange}  placeholder="Enter Email Name" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-field">
                      <label>Host</label>
                      <input type="text" name="Host" className="form-control"  value={formValues.title} 
                        onChange={handleInputChange}  placeholder="Host" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-field">
                      <label>Port</label>
                      <input type="text" name="Port" className="form-control"  value={formValues.title} 
                        onChange={handleInputChange}  placeholder="Port" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-field">
                      <label>Encryption</label>
                      <select className="form-control" value={formValues.sequence} 
                        onChange={handleInputChange} name="Encryption">
                        <option value="TLS">TLS</option>
                        <option value="TLS">TLS</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-field">
                      <label>Username</label>
                      <input type="text" name="Username" className="form-control"  value={formValues.link} 
                        onChange={handleInputChange}  placeholder="Enter Username" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-field">
                      <label>Password</label>
                      <input type="text" name="Password" className="form-control"  value={formValues.link} 
                        onChange={handleInputChange}  placeholder="Enter Password" />
                    </div>
                  </div>
                </div>
                <button className="site-btn-green">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailNotification