import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useAsync from '../hooks/useAsync';
import NotificationServices from '../services/NotificationServices.js';
import DeleteButton from './Button/DeleteButton';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function Push() {
  const [push, setPush] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  
  const { data, error, isLoading, run } = useAsync(NotificationServices.getPushNotification);
  console.log(">>>",data)

  useEffect(() => {
    if (data?.data) {
      setPush(data.data);
    }
  }, [data]);

  const handleDelete = (push) => {
    setSelectedEdit(push);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return formattedDate;
  }

  const filteredPush = push.filter((push) =>
    push.title && push.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Push Notification List <span className="badge bg-orange">{filteredPush.length}</span></h2>
        <div className="container-box-top-header-right">
          <Link className="round-add-btn" to="/push-notification"><img src="img/plus.svg" alt="Add" /></Link>
        </div>
      </div>
      <div className="container-box px-0">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input type="search" name="search" placeholder="Search" value={searchTerm} onChange={handleSearchChange} />
            <button className="search-btn">Search</button>
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Message</th>
                <th>UserType</th>
                <th>Schedule Date</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {filteredPush.map((push, index) => (
                <tr key={push._id}>
                  <td>{index + 1}</td>
                  <td>{push.title}</td>
                  <td>{push.body}</td>
                  <td>{push.userFor}</td>
                  <td>{formatDateTime(push.date)}</td>
                  <td>
                    <div className="action_toggle_outer">
                      <button
                        className="action_toggle"
                        onClick={() => toggleActionMenu(index)}
                      >
                        <i className="fa fa-ellipsis-v" />
                      </button>
                      {activeIndex === index && (
                        <div className="action_toggle_content product-edit show d-block">
                          <ul className="list-unstyled">
                            <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                            <li><button className="reject" onClick={() => handleDelete(push)}>Delete</button></li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Delete Confirmation"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <DeleteButton data={selectedEdit} page="pushnotification" closeModal={closeDeleteModal} onSuccess={run} />
      </Modal>
    </div>
  );
}

export default Push;
