// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking-details-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
  }
  
  .booking-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .trip-details {
    flex: 1 1;
  }
  
  .trip-info {
    flex: 1 1;
    text-align: right;
  }
  
  .traveller-section, .day-details, .cancellation-section {
    margin-bottom: 20px;
    padding: 15px;
    border-top: 1px solid #ccc;
  }
  
  .traveller-info p, .day-info p, .cancellation-info p {
    margin: 5px 0;
  }
  
  
  strong {
    font-weight: bold;
  }
  
  .cancellation-section {
    background-color: #f9f9f9;
    padding: 15px;
  }
  
  .cancellation-info {
    background-color: #e9e9e9;
    padding: 10px;
    border-radius: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/component/Header/Css/BookingDetails.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,SAAO;EACT;;EAEA;IACE,SAAO;IACP,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,0BAA0B;EAC5B;;EAEA;IACE,aAAa;EACf;;;EAGA;IACE,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,kBAAkB;EACpB","sourcesContent":[".booking-details-container {\n    margin: 20px;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    background-color: #fff;\n  }\n  \n  .booking-header {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 20px;\n  }\n  \n  .trip-details {\n    flex: 1;\n  }\n  \n  .trip-info {\n    flex: 1;\n    text-align: right;\n  }\n  \n  .traveller-section, .day-details, .cancellation-section {\n    margin-bottom: 20px;\n    padding: 15px;\n    border-top: 1px solid #ccc;\n  }\n  \n  .traveller-info p, .day-info p, .cancellation-info p {\n    margin: 5px 0;\n  }\n  \n  \n  strong {\n    font-weight: bold;\n  }\n  \n  .cancellation-section {\n    background-color: #f9f9f9;\n    padding: 15px;\n  }\n  \n  .cancellation-info {\n    background-color: #e9e9e9;\n    padding: 10px;\n    border-radius: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
