import requests from "./httpService";

const PackageServices = {

    getPackage : async () =>{
       
        return requests.get(`/package`);
    },
    getPackageById : async (id) =>{
       
        return requests.get(`/package/${id}`);
    },
    getWebPackage : async () =>{
       
        return requests.get(`/package/web`);
    },
    getDestinationPackage : async (id) =>{
       
        return requests.get(`/package/web/${id}`);
    },
    createPackage : async (body) =>{
        // console.log(">>>>",body)
        return requests.post(`/package`,body);
    },
    uploadImage : async (body) =>{
        // console.log(">>>>",body)
        return requests.post(`/package/uploadImage`,body);
    },
    removeImage : async (body) =>{
        // console.log(">>>>",body)
        return requests.post(`/package/removeImage`,body);
    },
    updatePackage : async (id,body) =>{
        return requests.put(`/package/${id}`,body);
    },
    addDayToSchedule : async (id,body) =>{
        console.log(">>>",body)
        return requests.post(`/package/${id}/schedule`,body);
    },
    updateDayToSchedule : async (id,dayId,body) =>{
        console.log(">>>",body)
        return requests.put(`/package/${id}/schedule/${dayId}`,body);
    },
    removeDayToSchedule : async (id,dayId,body) =>{
        console.log(">>>",body)
        return requests.delete(`/package/${id}/schedule/${dayId}`,body);
    },


    deletePackage  : async (id) =>{
        // console.log(">>",id)
        return requests.delete(`/package/${id}`);
    }
 

}

export default PackageServices;