import React, { useState } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import NotificationServices from '../../services/NotificationServices';
import Pagetitle from '../../component/PageTitle';

function AddNotification() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    title: '',
    message: '',
    image: null,  // Set image to null initially
    linkUrl: '',
  });
  const [previewImage, setPreviewImage] = useState('img/placeholder-img.png'); // Placeholder image path


  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormValues({
        ...formValues,
        image: file,
      });
    }
  };
//   // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      console.log(formData);
    for (const key in formValues) {
      formData.append(key, formValues[key]);
    }
      console.log(formValues)
     const response = await NotificationServices.createNotification(formData);
      // Assuming updateContactDetail is a method in UserServices
//  console.log(response);
      alert('Notification Added Successfully');
      navigate('/admin/notification-list');
      } catch (error) {
      console.error('Failed to notification details', error);
      alert('Failed to add Notification details');
    }
  };

  return (
    <>
      <div className="right_col" role="main">
        <Pagetitle />
        <div className="container-box">
          <div className="container-box-top-header justify-content-end">
            <div className="sub-title-box-right">
              <Link className="site-btn-green" to="/admin/notification-list">Notification List</Link>
            </div>
          </div>
          <div className="container-box-inner px-4">
            <div className="page-details">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="input-field">
                      <label>Title*</label>
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        value={formValues.title}
                        onChange={handleInputChange}
                        placeholder="New notification"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="input-field">
                      <label>Description*</label>
                      <textarea
                        className="form-control video-desc"
                        name="message"
                        value={formValues.message}
                        onChange={handleInputChange}
                        placeholder="Description"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div className="input-field">
                      <label>Upload<span className="red">*</span></label>
                      <input type="file" required name="image" className="form-control" onChange={handleFileChange} />
                      <p><span>Drop an image or Paste URL (upto resolution 1,500 x 1,500 px),</span> Supported formats: png, jpeg, jpg, webp</p>
                      <div className="file-preview text-center">
                        <img id="uploadFile" src={previewImage} alt="your image" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-field">
                      <label>Redirect URL</label>
                      <input
                        type="text"
                        name="linkUrl"
                        className="form-control"
                        value={formValues.linkUrl}
                        onChange={handleInputChange}
                        placeholder="Redirect URL"
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="site-btn-green">Send Notification</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNotification;
