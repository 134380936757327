import React, { useState } from 'react';
import Modal from 'react-modal';
import NotificationServices from '../services/NotificationServices';
import { Link } from 'react-router-dom';
import Pagetitle from './PageTitle';

const PushNotification = () => {
    const [title, setTitle] = useState('');
    const [userRole, setUserRole] = useState('');
    const [message, setMessage] = useState('');
    const [scheduledDate, setScheduledDate] = useState('');

    const handleSubmit = async(event) => {
        event.preventDefault();
        
        const notificationData = {
            title,
            userRole,
            message,
            scheduledDate,
        };
        // You can call your NotificationServices to send the data
        try {
            const res = await NotificationServices.sendPushNotification(notificationData)
            console.log('Notification sent successfully:', res);
            if(res.status === true) {   
                alert('Notification sent successfully');
            }else{
                alert('Something went wrong');
            }
        } catch (error) {
            console.error('Failed to send notification:', error);
            alert('Something went wrong');
        }
       
           
    };

    return (
        <div className="right_col" role="main">
            <Pagetitle></Pagetitle>
            {/* <div className="title-box">
                
                <div className="container-box-top-header-right"></div>
            </div>
            <div className="sub-title-box">
        <div className="sub-title-box-left" >
        <h2>Push Notification</h2>

        </div>
        <div className="container-box-top-header-right">
          <Link className="list-banner-btn" to="/push-notification-list ">Push Notification List</Link>
        </div>
        
      </div> */}
           
            <div className="container-box p-4 pb-2">
                <div className="container-box-inner">
                    <div className="page-details">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                    <div className="input-field">
                                        <label htmlFor="title">Server Key*</label>
                                        <input
                                            type="text"
                                            id="serverKey"
                                            className="form-control" 
                                            placeholder='Enter Server Key'
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            required
                                        />
                                    </div>
                            </div>
                            <button className="site-btn-green" type="submit"> SUBMIT </button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="container-box p-4">
                <div className="container-box-inner">
                <h5>Push Messages</h5>
                    <div className="page-details mt-3">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="input-field">
                                    <div class="status-toggle">
                                        <div class="check-box">
                                            <input type="checkbox" name='Invalid Mobile Number' checked />
                                        </div>
                                        <label htmlFor="Invalid Mobile Number">Invalid Mobile Number Message</label>
                                    </div>
                                    <input
                                        type="text"
                                        id="serverKey"
                                        className="form-control" 
                                        placeholder='Enter Invalid Mobile Number Message'
                                        value='Please enter valid mobile number'
                                        onChange={(e) => setTitle(e.target.value)}
                                        required
                                    />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="input-field">
                                    <div class="status-toggle">
                                        <div class="check-box">
                                            <input type="checkbox" name='Wrong OTP' checked />
                                        </div>
                                        <label htmlFor="Wrong OTP">Wrong OTP</label>
                                    </div>
                                    <input
                                        type="text"
                                        id="serverKey"
                                        className="form-control" 
                                        placeholder='Enter Wrong OTP Message'
                                        value='Wrong OTP, Please check!'
                                        onChange={(e) => setTitle(e.target.value)}
                                        required
                                    />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="input-field">
                                    <div class="status-toggle">
                                        <div class="check-box">
                                            <input type="checkbox" name='Expired plan' checked />
                                        </div>
                                        <label htmlFor="Expired plan">Expired plan message</label>
                                    </div>
                                    <input
                                        type="text"
                                        id="serverKey"
                                        className="form-control" 
                                        placeholder='Enter Expired plan message'
                                        value='Lorem ipsum'
                                        onChange={(e) => setTitle(e.target.value)}
                                        required
                                    />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="input-field">
                                    <div class="status-toggle">
                                        <div class="check-box">
                                            <input type="checkbox" name='Before Plan Expire Message' checked />
                                        </div>
                                        <label htmlFor="Before Plan Expire Message">Before Plan Expire Message</label>
                                    </div>
                                    <input
                                        type="text"
                                        id="serverKey"
                                        className="form-control" 
                                        placeholder='Before Plan Expire Message'
                                        value='Your plan will expire soon..'
                                        onChange={(e) => setTitle(e.target.value)}
                                        required
                                    />
                                    </div>
                                </div>
                            </div>
                            <button className="site-btn-green" type="submit"> SUBMIT </button>
                        </form>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default PushNotification;
