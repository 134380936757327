import React, { useState, useEffect } from 'react';
import Pagetitle from './PageTitle';
import useAsync from '../hooks/useAsync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import NotificationServices from '../services/NotificationServices';
import HelpTogal from './TogelButton/HelpTogal';
// import HelpTogal from './TogelButton/HelpTogal';
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import NotificationUpdate from './Update Model/NotificationUpdate';
import { Link } from 'react-router-dom';

Modal.setAppElement('#root');

function Notification() {
  const { data, error, isLoading, run } = useAsync(NotificationServices.getNotification);
  const count = data?.data?.length;
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(data?.data || []);

  useEffect(() => {
    if (data?.data) {
      setFilteredData(
        data.data.filter(notification =>
          notification.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          notification.message.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [data, searchTerm]);

  const handleEditDetails = (help) => {
    setSelectedEdit(help);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };

  const handleDelete = (help) => {
    setSelectedEdit(help);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return formattedDate;
  }
  function truncateText(text, limit) {
    const words = text.split(' ');
    if (words.length > limit) {
      return words.slice(0, limit).join(' ') + '...';
    }
    return text;
  }

  return ( 
    <>
      <div className="right_col" role="main">
      <Pagetitle></Pagetitle>
        <div className="container-box px-0">
        <div className="container-box-top-header justify-content-end">
          <div className="sub-title-box-right">
          <Link className="Add-new-btn site-btn-green" style={{backgroundColor:"#FF7029"}} to="/admin/add-notification">
              <img src="img/plus-circle.svg" className='me-2' alt='' />Add New
            </Link>
          </div>
        </div>
          <div className="container-box-inner">
            <table id="example" className="table table-striped" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th>Title</th>
                  <th className="w-50">Description</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((notification, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <div className="product-img">
                        {/* <img src={process.env.REACT_APP_URL + notification?.image} alt="" /> */}
                        <img src={process.env.REACT_APP_URL + notification?.image} alt="" style={{ height: '70px', width: '70px', objectFit: 'contain' }} />
                      </div>
                    </td>
                    <td>{truncateText(notification?.title, 10)}</td>
                    <td className="address">{truncateText(notification.message, 20)}</td>
                    <td className="payment">{formatDateTime(notification.createdAt)}</td>
                    <td className="status-toggle">
                      <HelpTogal help={notification} page='notification' onSuccess={() => run()} />
                    </td>
                    <td>
                    <button className="view-details-btn edit-btn" onClick={() => handleEditDetails(notification)}><FontAwesomeIcon icon={faEdit} /></button>
                  </td>
                  <td>
                    <button className="view-details-btn delete-btn" onClick={() => handleDelete(notification)}><FontAwesomeIcon icon={faTrash} /></button>
                  </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <NotificationUpdate notification={selectedEdit} closeModal={closeEditModal} onSuccess={run} />
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEdit} page="notification" closeModal={closeDeleteModal} onSuccess={run} />
        </Modal>
      </div>
    </>
  );
}

export default Notification;
