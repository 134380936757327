import React, { useState, useEffect } from 'react';
import useAsync from '../../hooks/useAsync';
import { useParams } from 'react-router-dom';
import Footer from './Common/footer';
import BookingServices from '../../services/BookingServies';
import moment from "moment";

function ViewBooking() {
    const { id } = useParams(); // Get the package ID from the URL
    const [booking, setBooking] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchPackage = async () => {
            try {
                const response = await BookingServices.getMyBookingById(id);
                // console.log("response", response); // Log the entire response
                setBooking(response.data); // Set pkg to the appropriate part of the response
            } catch (err) {
                setError('Error loading package details');
            } finally {
                setIsLoading(false);
            }
        };

        fetchPackage();
    }, [id]);
    const formatDay = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayOfWeek = daysOfWeek[date.getDay()];

        return `${dayOfWeek}`;
    };
    const onDownloadInvoice = async (bookingId) => {
        try {
            // Make API call to get the invoice URL
            const response = await BookingServices.downloadInvoice(bookingId);
            if (response.status === 200 || response.url) {
                // Open the invoice URL in a new tab
                window.open(`${process.env.REACT_APP_URL}${response.url}`, '_blank');
            } else {
                alert("Unable to download invoice. Please try again later.");
            }
        } catch (error) {
            console.error("Error downloading invoice:", error);
            alert("An error occurred while downloading the invoice.");
        }
    };
    const onDownloadCancelInvoice = async (bookingId) => {
        try {
            // Make API call to get the invoice URL
            const response = await BookingServices.downloadCancelInvoice(bookingId);
            if (response.status === 200 || response.url) {
                // Open the invoice URL in a new tab
                window.open(`${process.env.REACT_APP_URL}${response.url}`, '_blank');
            } else {
                alert("Unable to download invoice. Please try again later.");
            }
        } catch (error) {
            console.error("Error downloading invoice:", error);
            alert("An error occurred while downloading the invoice.");
        }
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}-${month}-${year}`;
    };
    const formatDateMonth = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';

        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-US', options); // "22 Oct, 2024"
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <>
            <section className="notification-section order-section">
                <div className="container">
                    <div className="tour-package-outer">
                        <h3 className="banner-heading">View Booking</h3>
                    </div>
                </div>
            </section>
            <section className="all-package-section">
                <div className="container">
                    <div className="heading-outer d-flex" style={{ marginBlockStart: 20 }}>
                        <div className="main-heading-outer">
                            <h3 className="main-heading">View Booking</h3>
                        </div>
                        <div className="view-all-btn">
                            <a href="#"> </a>
                        </div>
                    </div>
                    <div className="notification-inner">
                        <div className="row">
                            <div className="col-lg-4">
                                <h5 className="heading">{booking?.package?.packageName}</h5>
                                <p className="sub-heading" style={{ marginBottom: 10 }}>
                                    {booking?.package?.startLocation} to {booking?.package?.endLocation}
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <div className="booking-date-outer d-flex">
                                    <div className="booking-outer-2">
                                        <h5 className="heading" style={{ fontSize: 16 }}>
                                            {formatDateMonth(booking?.startDate)}
                                        </h5>
                                        <p className="sub-heading" style={{ marginBottom: 10 }}>
                                            {formatDay(booking?.startDate)}
                                        </p>
                                    </div>
                                    <div className="booking-outer-3">
                                        <a
                                            href="#"
                                            className=" package-day"
                                            style={{
                                                background: "rgba(108, 112, 114, 0.15)",
                                                color: "#6C7072"
                                            }}
                                        >
                                            {booking?.package?.duration}
                                        </a>
                                    </div>
                                    <div className="booking-outer-2">
                                        <h5 className="heading" style={{ fontSize: 16 }}>
                                            {formatDateMonth(booking?.endDate)}
                                        </h5>
                                        <p className="sub-heading" style={{ marginBottom: 10 }}>
                                            {formatDay(booking.endDate)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="booking-date-outer">
                                    <div className="booking-outer-2">
                                        <h5
                                            className="heading booking-heading"
                                            style={{ fontSize: 16, textAlign: "end" }}
                                        >
                                            {/* 2 Travellers: <span>2 Adults / From New Delhi</span> */}
                                            {booking?.passengers?.length} Travellers:
                                            <span>
                                                {booking.passengers.filter(p => p.age >= 15).length} Adults,
                                                {booking.passengers.filter(p => p.age < 15).length} Child
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="day-plan-border" style={{ marginBlock: 15 }} />
                            <div className="col-lg-6">
                                <div className="booking-date-outer d-flex">
                                    <div className="booking-outer-2">
                                        <h5
                                            className="heading"
                                            style={{ fontSize: 16, marginBottom: 10 }}
                                        >
                                            Price Breakup
                                        </h5>
                                        <div className="view-booking-outer d-flex  ">
                                            <h5 className="heading" style={{ fontSize: 16 }}>
                                                Price
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer "
                                    style={{ justifyContent: "end", marginBlockStart: 29 }}
                                >
                                    <div className="booking-outer-2">
                                        <div className="view-booking-outer   ">
                                            <p className="sub-heading" style={{ textAlign: "right" }}>
                                                {booking?.package?.discountPrice}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer d-flex"
                                    style={{ marginBlockEnd: 10 }}
                                >
                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                        Member Quantiy
                                    </h5>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer "
                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                >
                                    <div className="booking-outer-2">
                                        <div className="view-booking-outer   ">
                                            <p className="sub-heading" style={{ textAlign: "right" }}>
                                                {booking?.quantity}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer d-flex"
                                    style={{ marginBlockEnd: 10 }}
                                >
                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                        Tax
                                    </h5>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer "
                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                >
                                    <div className="booking-outer-2">
                                        <div className="view-booking-outer   ">
                                            <p className="sub-heading" style={{ textAlign: "right" }}>
                                                {booking?.tax}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="day-plan-border" style={{ marginBlock: 10 }} />
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer d-flex"
                                    style={{ marginBlockEnd: 10 }}
                                >
                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                        Total Amount to be paid
                                    </h5>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer "
                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                >
                                    <div className="booking-outer-2">
                                        <div className="view-booking-outer   ">
                                            <p className="sub-heading" style={{ textAlign: "right" }}>
                                                {booking?.totalAmount}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="day-plan-border" style={{ marginBlock: 10 }} />
                            <div className="col-lg-6">
                                <div className="booking-date-outer">
                                    <div className="booking-outer-2">
                                        <h5
                                            className="heading"
                                            style={{ fontSize: 16, marginBottom: 10 }}
                                        >
                                            Passenger Details
                                        </h5>
                                        <table style={{ width: "100%" }}>
                                            <thead>
                                                <tr style={{ backgroundColor: "#D1D1D1" }}>
                                                    <th
                                                        className="package-table-heading"
                                                        style={{ padding: "10px 15px" }}
                                                    >
                                                        S. NO
                                                    </th>
                                                    <th
                                                        className="package-table-heading"
                                                        style={{ padding: "10px 15px" }}
                                                    >
                                                        NAME
                                                    </th>

                                                    <th
                                                        className="package-table-heading"
                                                        style={{ padding: "10px 15px" }}
                                                    >
                                                        AGE
                                                    </th>
                                                    <th
                                                        className="package-table-heading"
                                                        style={{ padding: "10px 15px" }}
                                                    >
                                                        GENDER
                                                    </th>
                                                    <th
                                                        className="package-table-heading"
                                                        style={{ padding: "10px 15px" }}
                                                    >
                                                        CITY
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {booking?.passengers?.map((passenger, index) => (
                                                    <tr key={index}>
                                                        <td style={{ padding: 10 }}>{index + 1}.</td>
                                                        <td style={{ padding: 10, color: "#595959" }}>{passenger.name}</td>
                                                        <td style={{ padding: 10, color: "#595959" }}>{passenger.age}</td>
                                                        <td style={{ padding: 10, color: "#595959" }}>{passenger.gender}</td>
                                                        <td style={{ padding: 10, color: "#595959" }}>{passenger.city}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="booking-date-outer">
                                    <div className="booking-outer-2">
                                        <h5
                                            className="heading"
                                            style={{ fontSize: 16, marginBottom: 10 }}
                                        >
                                            Contact Information
                                        </h5>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer"
                                                    style={{ marginBlockEnd: 10 }}
                                                >
                                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                                        Name
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer "
                                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                                >
                                                    <div className="booking-outer-2">
                                                        <div className="view-booking-outer   ">
                                                            <p
                                                                className="sub-heading"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                {booking?.contact?.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer"
                                                    style={{ marginBlockEnd: 10 }}
                                                >
                                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                                        Email Id
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer "
                                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                                >
                                                    <div className="booking-outer-2">
                                                        <div className="view-booking-outer   ">
                                                            <p
                                                                className="sub-heading"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                {booking?.contact?.email}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer"
                                                    style={{ marginBlockEnd: 10 }}
                                                >
                                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                                        Phone Number
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer "
                                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                                >
                                                    <div className="booking-outer-2">
                                                        <div className="view-booking-outer   ">
                                                            <p
                                                                className="sub-heading"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                {booking?.contact?.mobileNo}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer"
                                                    style={{ marginBlockEnd: 10 }}
                                                >
                                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                                        State
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer "
                                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                                >
                                                    <div className="booking-outer-2">
                                                        <div className="view-booking-outer   ">
                                                            <p
                                                                className="sub-heading"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                {booking?.contact?.state}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer"
                                                    style={{ marginBlockEnd: 10 }}
                                                >
                                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                                        City
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer "
                                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                                >
                                                    <div className="booking-outer-2">
                                                        <div className="view-booking-outer   ">
                                                            <p
                                                                className="sub-heading"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                {booking?.contact?.city}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer"
                                                    style={{ marginBlockEnd: 10 }}
                                                >
                                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                                        Address
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer "
                                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                                >
                                                    <div className="booking-outer-2">
                                                        <div className="view-booking-outer   ">
                                                            <p
                                                                className="sub-heading"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                {booking?.contact?.address}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="day-plan-border" style={{ marginBlock: 10 }} />
                            <div
                                className="change-btn download-btn d-flex"
                                style={{ marginTop: 12 }}
                            >
                                {/* <a href="#" onClick={() => onDownloadInvoice(booking._id)}  style={{ backgroundColor: "#FF7029" }} >
                                    View Invoice
                                </a> */}
                                {/* <a href="#" onClick={() => onDownloadInvoice(booking._id)}  style={{ backgroundColor: "#FF7029" }}> View Invoice </a> */}
                                <a
                                    href="#"
                                    onClick={() =>
                                        booking.status === "Cancelled"
                                            ? onDownloadCancelInvoice(booking._id)
                                            : onDownloadInvoice(booking._id)
                                    }
                                    style={{ backgroundColor: "#FF7029" }}
                                >
                                    View Invoice
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer component */}
            <Footer />
        </>

    );
}

export default ViewBooking;
