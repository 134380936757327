import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import UserServices from '../../services/UserServices';
import Pagetitle from '../../component/PageTitle';

function UserAdd() {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    mobileNo: '',
    state: '',
    district: '',
    policeStation: '',
    pincode: '',
    password: '',
  });

  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  // Fetch states on component mount
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await UserServices.getState();
        setStates(response.data); // Assuming response.data contains the list of states
      } catch (error) {
        console.error('Failed to fetch states', error);
      }
    };

    fetchStates();
  }, []);

  // Fetch districts based on selected state
  useEffect(() => {
    const fetchDistricts = async () => {
      if (formValues.state) {
        try {
          const response = await UserServices.getDistrictByState(formValues.state);
          setDistricts(response.data); // Assuming response.data contains the list of districts
        } catch (error) {
          console.error('Failed to fetch districts', error);
        }
      }
    };

    fetchDistricts();
  }, [formValues.state]);

  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await UserServices.createAdmin(formValues);
      alert('Admin user created successfully');
    } catch (error) {
      console.error('Failed to create admin user', error);
    }
  };

  return (
    <div className="right_col" role="main">
      {/* <div className="title-box">
        <h2>Admin User Add</h2>
        <div className="container-box-top-header-right">
          <Link className="list-banner-btn" to="/admin-user">User List</Link>
        </div>
      </div> */}
      <Pagetitle></Pagetitle>
      {/* <div className="container-box px-5">
        <div className="container-box-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="page-details">
                <form onSubmit={handleSubmit}>
                <div className='row'>
                <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>User Type</label>
                    <select
                      className="form-control"
                      value={formValues.role}
                      onChange={handleInputChange}
                      name="role"
                    >
                      <option value="">--Select Type--</option>
                      <option value="User">User</option>
                    </select>
                  </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formValues.name}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  </div>
                  
                 
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formValues.email}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>Mobile No.</label>
                    <input
                      type="text"
                      name="mobileNo"
                      value={formValues.mobileNo}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  </div>
                  
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>State</label>
                    <select
                      className="form-control"
                      name="state"
                      value={formValues.state}
                      onChange={handleInputChange}
                    >
                      <option value="">--Select State--</option>
                      {states.map((state) => (
                        <option key={state._id} value={state._id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>District</label>
                    <select
                      className="form-control"
                      name="district"
                      value={formValues.district}
                      onChange={handleInputChange}
                    >
                      <option value="">--Select District--</option>
                      {districts.map((district) => (
                        <option key={district._id} value={district._id}>
                          {district.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>Police Station</label>
                    <input
                      type="text"
                      name="policeStation"
                      value={formValues.policeStation}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>Pincode</label>
                    <input
                      type="text"
                      name="pincode"
                      value={formValues.pincode}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      value={formValues.password}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  </div>
                  </div>
                  <button className="site-btn-green">SUBMIT</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container-box ">
      <div className="container-box-top-header justify-content-end">
          <div className="sub-title-box-right">
          <Link className="site-btn-green" to="/user-manager">User List</Link>
          </div>
        </div>
        <div className="container-box-inner px-4">
          <div className="row">
            <div className="col-md-12">
              <div className="page-details">
                <form onSubmit={handleSubmit}>
                <div className='row'>
                <div className="col-lg-4 col-md-6">
                <div className="input-field">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formValues.name}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder='Enter Name'
                    />
                  </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>Mobile No.</label>
                    <input
                      type="text"
                      name="mobileNo"
                      value={formValues.mobileNo}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder='Enter Mobile no'
                    />
                  </div>
                  </div>
                 
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formValues.email}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder='Enter Email'
                    />
                  </div>
                  </div>
                  
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>City</label>
                    <input
                      type="text"
                      name="city"
                      value=''
                      className="form-control"
                      placeholder='Enter City'
                    />
                  </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>Select Plan Name</label>
                    <select
                      className="form-control"
                      name="state"
                      value={formValues.state}
                      onChange={handleInputChange}
                    >
                      <option value="">--Select Plan Name--</option>
                      {states.map((state) => (
                        <option key={state._id} value={state._id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>Select Plan Type</label>
                    <select
                      className="form-control"
                      name="state"
                      value={formValues.state}
                      onChange={handleInputChange}
                    >
                      <option value="">--Select Plan Type--</option>
                      {states.map((state) => (
                        <option key={state._id} value={state._id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  </div>
                  </div>
                  <button className="site-btn-green">SUBMIT</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAdd;
