import React,{useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';

function Footer() {
  const location = useLocation();
  
  // Function to determine if a link is active
  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

    // Scroll to the top of the page on route change
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

  return (
    <footer> 
      <div className="container">
        <div className="footer-inner">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-icon-outer">
                <div className="footer-logo">
                  <a href="#">
                    <img src="/img/logo.svg" alt="footer-logo" />
                  </a>
                </div>
                <div className="footer-content">
                  <p className="footer-descripation">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since.
                  </p>
                </div>
                <div className="footer-content social-links mb-4">
                  <ul className="d-flex list-unstyled">
                    <li><a href="https://www.facebook.com/" target='_blank'><i className="fa fa-facebook" /></a></li>
                    <li><a href="https://www.instagram.com/" target='_blank'><i className="fa fa-instagram" /></a></li>
                    <li><a href="https://www.x.com/" target='_blank'><i className="fa fa-twitter" /></a></li>
                    <li><a href="https://www.pinterest.com/" target='_blank'><i className="fa fa-pinterest" /></a></li>
                    <li><a href="https://www.youtube.com/" target='_blank'><i className="fa fa-youtube-play" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="center-details details-2">
                <h3 className="center-details-heading">Contact</h3>
                <div className="contact-details">
                  <ul className="list-unstyled">
                    <li><span><i className="fa fa-phone" /></span><a href="#">+91 3322232222</a></li>
                    <li><span><i className="fa fa-envelope" /></span><a href="#"> winjotravel@gmail.com</a></li>
                    <li><span><i className="fa fa-map-marker" /></span><a href="#"> House No .98, Dream City, Suratgarh, Ganganagar-335804, Rajasthan</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-quicklinks">
                <h2>Links</h2>
                <div className="quicklinks-contant">
                  <ul className="list-unstyled">
                    <li className={isActive('/')}>
                      <Link to="/">Home</Link>
                    </li>
                    <li className={isActive('/order')}>
                      <Link to="/order">Order</Link>
                    </li>
                    <li className={isActive('/privacy')}>
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                    {/* <li className={isActive('/booking')}>
                      <Link to="/booking">Booking</Link>
                    </li> */}
                    <li className={isActive('/contactUs')}>
                      <Link to="/contactUs">Contact us</Link>
                    </li>
                    <li className={isActive('/tour-package')}>
                      <Link to="/tour-package">Package</Link>
                    </li>
                    <li className={isActive('/terms')}>
                      <Link to="/terms">Terms & Conditions</Link>
                    </li>
                    <li className={isActive('/notification')}>
                      <Link to="/notification">Notification</Link>
                    </li>
                    <li className={isActive('/wishlist')}>
                      <Link to="/wishlist">Wishlist</Link>
                    </li>
                    <li className={isActive('/refund')}>
                      <Link to="/refund">Refund Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-border"></div>
      <div className="development-company-name">
       <h3>© 2024 Winja Travel Made by <a href="https://truevalueinfosoft.com/">True Value Infosoft </a> (P) Limited</h3>
   
      </div>
    </footer>
  );
}

export default Footer;
