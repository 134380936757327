import React, { useState, useEffect } from 'react';
import Pagetitle from './PageTitle';
import useAsync from '../hooks/useAsync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import DestinationServices from '../services/DestinationServices';
import HelpTogal from './TogelButton/HelpTogal';
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import DestinationUpdate from './Update Model/UpdateDestination';
import { Link } from 'react-router-dom';

Modal.setAppElement('#root');

function Destination() {
  const { data, error, isLoading, run } = useAsync(DestinationServices.getDestination);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (data?.data) {
      // Filter data based on searchQuery
      setFilteredData(
        data.data.filter(destination =>
          destination.title.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
  }, [data, searchQuery]);

  const handleEditDetails = (help) => {
    setSelectedEdit(help);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };

  const handleDelete = (help) => {
    setSelectedEdit(help);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  }

  function truncateText(text, limit) {
    const words = text.split(' ');
    if (words.length > limit) {
      return words.slice(0, limit).join(' ') + '...';
    }
    return text;
  }

  return (
    <>
      <div className="right_col" role="main">
        <Pagetitle></Pagetitle>
        <div className="container-box px-0">
          <div className="container-box-top-header px-4">
            <div className="container-box-top-header-left-2">
              <input
                type="search"
                name="search"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <button className="search-btn"><FontAwesomeIcon icon={faSearch} /></button>
            </div>
            <div className="container-box-top-header justify-content-end">
              <div className="sub-title-box-right">
                <Link className="Add-new-btn site-btn-green" style={{ backgroundColor: "#FF7029" }} to="/admin/add-destination">
                  <img src="img/plus-circle.svg" className='me-2' alt='' />Add New
                </Link>
              </div>
            </div>
          </div>
          <div className="container-box-inner">
            <table id="example" className="table table-striped" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((destination, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <div className="product-img">
                        <img src={process.env.REACT_APP_URL + destination?.image} alt="" style={{ height: '70px', width: '70px', objectFit: 'contain' }} />
                      </div>
                    </td>
                    <td>{truncateText(destination?.title, 10)}</td>
                    <td className="payment">{formatDateTime(destination.createdAt)}</td>
                    <td className="status-toggle">
                      <HelpTogal help={destination} page='destination' onSuccess={() => run()} />
                    </td>
                    <td>
                      <button className="view-details-btn edit-btn" onClick={() => handleEditDetails(destination)}><FontAwesomeIcon icon={faEdit} /></button>
                    </td>
                    <td>
                      <button className="view-details-btn delete-btn" onClick={() => handleDelete(destination)}><FontAwesomeIcon icon={faTrash} /></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DestinationUpdate destination={selectedEdit} closeModal={closeEditModal} onSuccess={run} />
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEdit} page="destination" closeModal={closeDeleteModal} onSuccess={run} />
        </Modal>
      </div>
    </>
  );
}

export default Destination;
