import React, { useState,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminServices from '../../services/AdminServices';
// import { AuthContext } from '../../AuthContext';

const PasswordToggle = ({ password, setPassword }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="form-group">
      <label htmlFor="password">
        <i className="fa fa-lock" />
      </label>
      <input
        type={passwordVisible ? 'text' : 'password'}
        className="form-control"
        id="password"
        placeholder="Enter Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <span
        id="togglePassword"
        className={`fa ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'}`}
        onClick={togglePasswordVisibility}
        style={{ cursor: 'pointer'}}
      />
    </div>
  );
};

function Login({ setIsAuthenticated, setRole }) {
  const [mobileOrEmail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  // const { login } = useContext(AuthContext); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(mobileOrEmail, password);
    
    try {
      const data = await AdminServices.login({ mobileOrEmail, password });
      if (data.status === true) {
        console.log(data);
        localStorage.setItem('authToken', data?.token);
        localStorage.setItem('userRole', data?.data?.userType);
        localStorage.setItem('name', data?.data?.name);
        localStorage.setItem('image', data?.data?.image);
        
        // setIsAuthenticated(true);
        // setRole(data?.data?.userType || 'human');
           // Call the login function from AuthContext and store user info
          //  login(data?.data);

        navigate('/admin/dashboard');
      } else {
        setError("*Invalid Credentials");
      }
    } catch (error) {
      console.error('Login error:', error);
      setError("*Something went wrong. Please try again.");
    }
  };

  return (
    <div className="login-bg">
      <div className="login-container">
        <div className="login-left">
          <img
            src="img/login-img.png"
            alt="Login-bg"
            className="login-img"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </div>
        <div className="login-right">
          <form id="login-form" onSubmit={handleSubmit} method="post">
          <img
            src="img/logo1.svg"
            alt="Login-bg"
            className="login-img"
            style={{ maxWidth: '100%', height: 'auto', display: 'block', marginInline: 'auto' }}
            />
            <h2 style={{ color :'white'}}>Login</h2>
            <div className="form-group">
              <label htmlFor="username" className="icon-container">
                <i className="fa fa-envelope" />
              </label>
              <input
                type="email"
                className="form-control"
                id="userEmail"
                placeholder="example@example.com"
                value={mobileOrEmail}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <PasswordToggle password={password} setPassword={setPassword} />
            <h6 style={{ color: 'red' }}>{error}</h6>
            <div className="input-field mb-3">
              <input
                type="submit"
                className="login-btn"
                id="login-btn"
                value="Login"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
