import React, { useState, useEffect, useContext } from 'react';
import Sidebar from './SideBar';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext'; // Ensure correct path

function Header() {
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const { logout } = useContext(AuthContext); // Getting logout from AuthContext

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.classList.add('sidebar-open');
      document.body.classList.remove('sidebar-closed');
    } else {
      document.body.classList.add('sidebar-closed');
      document.body.classList.remove('sidebar-open');
    }
  }, [isSidebarOpen]);

  const name = localStorage.getItem("name");
  const image = localStorage.getItem("image");
  const navigate = useNavigate();

  // const logOut = () => {
  //   logout(); // Calling the logout function from AuthContext
  //   navigate('/admin/login');
  //   window.location.reload()
  // };
  const  logOut = () => {
    localStorage.clear();
    navigate('/admin/login');
    window.location.reload();
  }

  const goToProfile = () => {
    navigate('/admin/profile');
  };

  return (
   <>
   <div className="top_nav">
          <div className="nav_menu">
            <nav>
              <div className="toggle">
              <button className="btn toggle-btn" onClick={toggleSidebar}> {/* Toggle button */}
                <i className="fa fa-bars"></i>
              </button>
              </div>
              <ul className="nav navbar-nav navbar-right">
                <li className>
                  <Link data-bs-toggle="modal" data-bs-target="#supportModal">
                    <img src="img/chat.svg" alt="" />
                  </Link>
                </li>
                <li className='profile-text'>
                  <Link href="#" data-bs-toggle="modal" data-bs-target="#adminModel" className='d-flex align-items-center'>
                  <div className="admin-profile-img">
                    <img src={process.env.REACT_APP_URL + image || 'img/profile-img.png'} alt="Profile"/>
                  </div>
                  {name}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div
  className="modal fade support-modal"
  id="supportModal"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title text-center">Support</h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div className="modal-body px-5 py-4">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-12">
            <ul className="list-unstyled support-list">
              <li>
                <a href="tel:+91 9057801818">+91 9057801818</a>
                <a href="tel:+91 9057801818" className='contact-btn-right'>
                <span>
                  <i className="fa fa-phone" />
                </span>
                </a>
              </li>
              <li>
                <a href="mailto:info@truevalueinfosoft.com">
                  info@truevalueinfosoft.com
                </a>
                <a href="mailto:info@truevalueinfosoft.com" className='contact-btn-right'>
                <span>
                  <i className="fa fa-envelope" />
                </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div
  className="modal fade"
  id="adminModel"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div className="modal-dialog">
    <div className="modal-content">
      {/* <div className="modal-header">
        <h5 className="modal-title">Log Out</h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div> */}
      <div className="modal-body px-5 pb-0">
        <div className="row align-items-center">
        <div className="modal-body py-4 text-center">
                  <div className="admin-profile-img">
                    <img src={process.env.REACT_APP_URL + image || 'img/profile-img.png'} alt="Profile"/>
                  </div>
              <h4>{name}</h4>
              <p className="desc my-2">Member Since July, 2024</p>
          </div>
        </div>
      </div>
      <div className='model-footer'>
              <button className="site-btn btn" onClick={goToProfile} data-bs-dismiss="modal">
                Profile
              </button>
        <button className="site-btn btn" onClick={logOut}>Sign out </button>
      </div>
    </div>
  </div>
</div>
        <Sidebar isOpen={isSidebarOpen} />
   </>
        

        
  )
}

export default Header
