import React, { useEffect, useState } from "react";
import useAsync from "../hooks/useAsync";
import AdminServices from "../services/AdminServices";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import Pagetitle from "./PageTitle";

function Dashboard() {
  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
    seriesTrainer: [],
    seriesApointment: [],
    seriesService: [],
    seriesUser: [], // For user registration data
    seriesBooking: [],
  });

  const { data, error, isLoading } = useAsync(AdminServices.dashboard);

  useEffect(() => {
    if (data && data.data) {
      // Define 12 months categories
      const months = Array.from({ length: 12 }, (_, index) =>
        moment().month(index).format("MMM")
      );

      const userCounts = new Array(12).fill(0); // Array to store monthly user registration count
      const bookingCounts = new Array(12).fill(0); // Array to store monthly subscription count

      // Process User Data (userCount array from API)
      data.data.userCount?.forEach((user) => {
        const month = moment(user.createdAt).month(); // Get the month of the user's registration
        userCounts[month]++; // Increment the count for that month
      });

      // Process Subscription Data (subscriptionCount array from API)
      data.data.BookingCount?.forEach((booking) => {
        const month = moment(booking.createdAt).month(); // Get the month of the subscription
        bookingCounts[month]++; // Increment the count for that month
      });

      // Set the processed data for the chart
      setChartData({
        categories: months, // Months for X-axis
        seriesUser: [{ name: "User Registration", data: userCounts }], // Monthly user data
        seriesBooking: [{ name: "User Booking", data: bookingCounts }], // Monthly subscription data
      });
    }
  }, [data]);

  const chartOptions = {
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartData.categories, // Months
    },
    yaxis: {
      title: {
        text: "Counts (In 100s)",
      },
      labels: {
        formatter: function (value) {
          return (value / 100).toFixed(2); // Scale down to hundreds
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val; // Tooltip showing actual value
        },
      },
    },
  };


  return (
    <div className="main_container">
      {/* /top navigation */}
      {/* page content */}
      <div className="right_col " role="main">
        <div className="top-sec-heading">
          <Pagetitle></Pagetitle>
        </div>
        <div className="">
          <div className="container-box-inner">
            <div className="row g-4">
              <div className="col-xl-4 col-sm-6">
                <div className="g-box g-box1">
                  <div>
                    <div className="g-box-inner">
                      <h4>{data?.data?.totalUsers}</h4>
                      {/* <h4>2000</h4> */}
                      <img src="img/total-users.svg" alt="" />
                    </div>
                    <p>Total Users</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6">
                <div className="g-box g-box2">
                  <div>
                    <div className="g-box-inner">
                      <h4>{data?.data?.totalBookings}</h4>
                      {/* <h4>1800</h4> */}
                      <img src="img/total-subscription.svg" alt="" />
                    </div>
                    <p>Total Booking</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-sm-6">
                <div className="g-box g-box3">
                  <div>
                  <div className="g-box-inner">
                      <h4>200</h4>
                      <img src="img/total-free-users.svg" alt="" />
                    </div>
                    <p>Total Free Users</p>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-4 col-sm-6">
                <div className="g-box g-box4">
                  <div>
                    <div className="g-box-inner">
                      <h4>{data?.data?.totalBookingAmount}</h4>
                      <img src="img/total-subscription-amount.svg" alt="" />
                    </div>
                    <p>Total Amount</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-sm-6">
                <div className="g-box g-box5">
                  <div>
                  <div className="g-box-inner">
                      <h4>1800</h4>
                      <img src="img/total-subscription.svg" alt="" />
                    </div>
                    <p>Total Subscription Expired</p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-xl-4 col-sm-6">
                <div className="g-box g-box6">
                  <div>
                  <div className="g-box-inner">
                      <h4>200</h4>
                      <img src="img/total-measurement.svg" alt="" />
                    </div>
                    <p>Total Measurement</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
            <div className="container-box">
              <div className="container-box-top-header">
                <div className="container-box-top-header-left">
                  <h4>Users Registration History</h4>
                  <p>(Value In 100)</p>
                </div>
              </div>
              <div className="container-box-inner">
                <div className="user-history-chart mt-2">
                  <ReactApexChart
                    options={chartOptions}
                    series={chartData.seriesUser}
                    type="bar"
                    height={350}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="container-box">
              <div className="container-box-top-header">
                <div className="container-box-top-header-left">
                  <h4>Users Booking History</h4>
                  <p>(Value In 100)</p>
                </div>
              </div>
              <div className="container-box-inner">
                <div className="user-history-chart mt-2">
                  <ReactApexChart
                    options={chartOptions}
                    series={chartData.seriesBooking}
                    type="bar"
                    height={350}
                  />

                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Uncomment the following sections if needed */}
        {/* <div className="container-box qr-code-box">
          <div className="container-box-top-header">
            <div className="container-box-top-header-left">
              <span><img src="img/chart2.svg" alt="" /></span>
              <p>Total Trainer</p>
            </div>
          </div>
          <div className="container-box-inner mt-3 pt-4">
            <div className="qr-code-status mt-4">
              <ReactApexChart
                options={chartOptions}
                series={chartData.seriesTrainer}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div>
        <div className="container-box qr-code-box">
          <div className="container-box-top-header">
            <div className="container-box-top-header-left">
              <span><img src="img/chart2.svg" alt="" /></span>
              <p>Total Service Center</p>
            </div>
          </div>
          <div className="container-box-inner mt-3 pt-4">
            <div className="qr-code-status mt-4">
              <ReactApexChart
                options={chartOptions}
                series={chartData.seriesService}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div>
        <div className="container-box qr-code-box">
          <div className="container-box-top-header">
            <div className="container-box-top-header-left">
              <span><img src="img/chart2.svg" alt="" /></span>
              <p>Total Apointment</p>
            </div>
          </div>
          <div className="container-box-inner mt-3 pt-4">
            <div className="qr-code-status mt-4">
              <ReactApexChart
                options={chartOptions}
                series={chartData.seriesApointment}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div> */}
      </div>
      {/* /page content */}
    </div>
  );
}

export default Dashboard;
