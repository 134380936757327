// src/context/AuthContext.js
import React, { createContext, useState } from 'react';

// Create the AuthContext
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track if the user is logged in
  const [user, setUser] = useState(null); // Store user data (name, image, etc.)

  // Function to handle successful login (set authentication status and user details)
  const login = (userData) => {
    setIsAuthenticated(true);
    setUser(userData);
  };

  // Function to handle logout (clear authentication status and user details)
  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
