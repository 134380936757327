import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Common/footer';
import WishlistServices from '../../services/WishlistServices';

function Wishlist() {
  const [wishlistedPackages, setWishlistedPackages] = useState([]);

  useEffect(() => {
    const fetchWishlistedPackages = async () => {
      try {
        const token = localStorage.getItem('authToken'); // Assuming token is stored in localStorage
        const response = await WishlistServices.getWishlist(token);
        // console.log(">>>", response.data); // API response contains the wishlist items
        setWishlistedPackages(response.data); // Store the fetched data in the state
      } catch (error) {
        console.error('Failed to fetch wishlisted packages:', error);
      }
    };

    fetchWishlistedPackages();
  }, []);

  return (
    <>
      <section className="notification-section wishlist-section">
        <div className="container">
          <div className="tour-package-outer">
            <h3 className="banner-heading">Wishlist</h3>
          </div>
        </div>
      </section>

      <section className="all-package-section">
        <div className="container">
          <div className="breadcrumb-outer">
            <ul className="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Wishlist</li>
            </ul>
          </div>
          <div className="heading-outer d-flex">
            <div className="main-heading-outer">
              <h3 className="main-heading">Favorite Places</h3>
            </div>
            <div className="view-all-btn">
              {/* <a href="#"> View All</a> */}
            </div>
          </div>
          <div className="row">
            {wishlistedPackages.length > 0 ? (
              wishlistedPackages.map((packageItem, index) => (
                <div className="col-lg-3 col-md-4" key={index}>
                  <div className="card wishlist-inner" style={{ padding: 15 }}>
                    <Link to={`/package-details/${packageItem._id}`} className="">
                      <img
                        src={`${process.env.REACT_APP_URL || ''}${packageItem.images[0]}`}
                        className="card-img-top"
                        alt={packageItem.packageName}
                      />
                    </Link>
                    <div className="card-body">
                      <Link to={`/package-details/${packageItem._id}`} >
                        <h3 className="heading wishlist-heading" style={{ textAlign: "center", marginBottom: 0 }}>
                          {packageItem.packageName || 'Package Title'}
                        </h3>
                      </Link>
                      <p className="sub-heading" style={{ textAlign: "center" }}>
                        <i className="fa fa-map-marker" /> {packageItem?.destination?.title || 'Unknown Destination'}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No wishlisted packages available</p>
            )}
          </div>
        </div>
      </section>

      {/* Footer component */}
      <Footer />
    </>
  );
}

export default Wishlist;
