import React from 'react';

function ViewVendor({ vendor, closeModal }) {
  if (!vendor) return null;

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return { date: formattedDate, time: formattedTime };
  }

  const url = 'uploads/';
  const { date, time } = formatDateTime(vendor.createdAt);

  return (
    <div className="modal fade viewbox edit-box show d-block" id="manufacturerModal" tabIndex={-1} aria-labelledby="manufacturerModal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="manufacturerModal">Vendor Details</h5>
            <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
          </div>
          <div className="modal-body px-3">
          <div className="row">
            <div className="col-md-12">
              <div className="page-details">
                <form>
                <div className='row'>
                <div className="col-lg-6 col-md-6">
                <div className="input-field">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      value={vendor.name}
                      className="form-control"
                      disabled
                    />
                  </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                  <div className="input-field">
                    <label>Mobile No.</label>
                    <input
                      type="text"
                      name="mobileNo"
                      value={vendor.mobileNo}
                      className="form-control"
                      disabled
                    />
                  </div>
                  </div>
{/*                  
                  <div className="col-lg-6 col-md-6">
                  <div className="input-field">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={user.email}
                      className="form-control"
                      disabled
                    />
                  </div>
                  </div> */}
                  
                  <div className="col-lg-6 col-md-6">
                  <div className="input-field">
                    <label>Address</label>
                    <input
                      type="text"
                      name="city"
                      value={vendor.address}
                      className="form-control"
                      disabled
                    />
                  </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6">
                  <div className="input-field">
                    <label>Plan Name</label>
                    <input
                      type="text"
                      name="city"
                      value={user.planName}
                      className="form-control"
                      disabled
                    />
                  </div>
                  </div> */}
                  {/* <div className="col-lg-6 col-md-6">
                  <div className="input-field">
                    <label>Plan Type</label>
                    <input
                      type="text"
                      name="city"
                      value={user.planType}
                      className="form-control"
                      disabled
                    />
                  </div>
                  </div> */}
                  <div className="col-lg-6 col-md-6">
                  <div className="input-field">
                    <label>Registration Date</label>
                    <input
                      type="text"
                      name="city"
                      value={formatDate(vendor?.createdAt)}
                      className="form-control"
                      disabled
                    />
                  </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                  <div className="input-field">
                    <label>Status</label>
                    {/* <select className="form-control">
                      <option>Active</option>
                      <option>Disable</option>
                    </select> */}
                    <input
                      type="text"
                      name="city"
                      value={vendor?.status}
                      className="form-control"
                      disabled
                    />
                  </div>
                  </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewVendor;
