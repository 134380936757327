import React, { useState } from 'react';
import useAsync from '../../hooks/useAsync';
import { Link } from 'react-router-dom';
import PackageServices from '../../services/PackageServices';
import Footer from './Common/footer';

function Package() {
  const { data, error, isLoading } = useAsync(PackageServices.getWebPackage);
  const [showAll, setShowAll] = useState(false); // State to toggle view
  const packages = data?.data || [];

  // Limit to 5 notifications if `showAll` is false
  const displayedPackages = showAll ? packages : packages.slice(0, 6);

  const currentMonth = new Date().toLocaleString('default', { month: 'long' });

  return (
    <>
      <section className="tour-package-section">
        <div className="container">
          <div className="tour-package-outer">
            <h3 className="banner-heading">Tour Package</h3>
          </div>
        </div>
      </section>
      <section className="all-package-section">
        <div className="container">
          <div className="breadcrumb-outer">
            <ul className="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Tour Package</li>
            </ul>
          </div>
          <div className="heading-outer d-flex">
            <div className="main-heading-outer">
              <h3 className="main-heading">Popular Package</h3>
            </div>
            {packages.length > 6 && (
              <div className="view-all-btn">
                <a href="#" onClick={() => setShowAll(!showAll)}>
                  {showAll ? 'View Less' : 'View All'}
                </a>
              </div>
            )}
          </div>
          <div className="row">
            {displayedPackages.map((pkg) => (
              <div className="col-lg-6" key={pkg._id}>
                <div className="package-card-outer">
                  <div className="package-card d-flex">
                    <div className="package-card-img">
                    <Link to={`/package-details/${pkg._id}`} className="package-card-img">
                      <img src={`${process.env.REACT_APP_URL || ''}${pkg.images[0]}`} alt={pkg.packageName} />
                      </Link>
                    </div>
                    <div className="package-card-details">
                    <Link to={`/package-details/${pkg._id}`}>
                      <h5 className="heading" style={{ marginBottom: 10 }}>
                        {pkg.packageName}
                      </h5>
                      </Link>
                      <p className="sub-heading" style={{ marginBottom: 10 }}>
                        {pkg.startLocation} to {pkg.endLocation}                       
                         <span className="package-day ms-3">{pkg.duration}</span>                      
                      </p>
                      <div
                        className="quicklinks-contant package-card-Activities"
                        style={{ marginTop: 10 }}
                      >
                        <ul className="list-unstyled ">
                          {pkg.packageInclusions.map((inclusion, idx) => (
                            <li key={idx} dangerouslySetInnerHTML={{ __html: inclusion }} />
                          ))}
                        </ul>
                      </div>
                      <div className="destination-location">
                        <ul className="list-unstyled ">
                          <li>
                            <i className="fa fa-check" />
                            Start Location: {pkg.startLocation}
                          </li>
                          <li>
                            <i className="fa fa-check" />
                            End Location: {pkg.endLocation}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="price-content d-flex">
                  <p className="sub-heading ">This Price is lower than the average <br /> price in {currentMonth}</p>
                  <div className="package-card-details-price">
                  <p className="sub-heading" style={{ color: "black" }}>
                      <strong >
                      ₹{pkg.discountPrice} 
                      <span style={{color : "#595959"}}> /Person</span> 
                      </strong>
                      <span>
                        <br />
                        <span style={{color : "#595959"}}>
                        Total Price <del>₹{pkg.price}</del>
                        </span>
                      </span>
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer component */}
      <Footer />
    </>
  );
}

export default Package;
