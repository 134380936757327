import React, { useState } from 'react';
import useAsync from '../../hooks/useAsync';
import { Link } from 'react-router-dom';
import ContactServices from '../../services/ContactServices';
import Footer from './Common/footer'; // Ensure the path is correct

function Terms() {
  const { data, error, isLoading } = useAsync(ContactServices.getTermCondition);
  
 
  return (
    <>
      <section
        className="terms-condition-section"
        style={{ backgroundColor: "#FF7029" }}
      >
        <div className="container">
          <div className="tour-package-outer">
            <h3 className="banner-heading">Terms &amp; Conditions</h3>
          </div>
        </div>
      </section>

      <section className="all-package-section">
        <div className="container">
          <div className="breadcrumb-outer">
            <ul className="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li> Terms &amp; Conditions </li>
            </ul>
          </div>

          <div className="heading-outer d-flex">
            <div className="main-heading-outer">
              <h3 className="main-heading">
                {data?.data?.Title || 'Refund Policy'}
              </h3>
            </div>
          </div>

          <div className="notification-inner">
            <div className="notifcation-outer privacy-policy-outer">
              <div className="notification-content">
                <div
                  className="refund-policy-content"
                  style={{ marginBottom: 15, textAlign: "justify" }}
                  dangerouslySetInnerHTML={{ __html: data?.data?.English || '' }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
          {/* Footer component */}
          <Footer />
    </>
  );
}

export default Terms;
