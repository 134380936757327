import React, { useState, useEffect } from 'react';
import BookingServices from '../../services/BookingServies';

function SelectStatus({ data, page, onSuccess }) {
  const [selectedSequence, setSelectedSequence] = useState(data.status || "Pending");

  useEffect(() => {
    setSelectedSequence(data.status || "Pending");
  }, [data]);

  const handleSequenceChange = async (event) => {
    const newSequence = event.target.value;
    setSelectedSequence(newSequence);
    try {
      await BookingServices.updateBookingStatus(data._id, {
        status: newSequence,
      });
      onSuccess();
    } catch (error) {
      console.error('Failed to update Booking status', error);
      // Handle error accordingly, e.g., show an alert or notification
    }
  };

  return (
    <select
      className="form-select"
      value={selectedSequence}
      onChange={handleSequenceChange}
    >
        {/* <option value="booking">Booking</option> */}
      <option value="Pending">Pending</option>
      <option value="Confirmed">Confirmed</option>
      <option value="Cancelled">Cancelled</option>
      <option value="Completed">Completed</option>
      {/* <option value="rejected">Rejected</option> */}
    </select>
  );
}

export default SelectStatus;