import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AuthServices from '../../../services/AuthServices';
// import AuthContext from '../../../context/AuthContextLogin'; // Import AuthContext

function SignUP() {
  const [name, setName] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [city, setCity] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userToken, setUserToken] = useState(''); // Store token after OTP is sent

  // const { login } = useContext(AuthContext); // Use login function from AuthContext
  const navigate = useNavigate();

  // Handle form submission to send OTP
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await AuthServices.register({ name, mobileNo, city, userType: 'user' });
      if (response.status === true) {
        setOtpSent(true); // OTP has been sent, proceed to OTP input
        setUserToken(response.data.token); // Store the token for verification
      } else {
        setError(response.message || 'Failed to send OTP. Please try again.'); // Show error from backend
      }
    } catch (error) {
      const errorMsg = error?.response?.data?.message || 'An unexpected error occurred. Please try again.';
      setError(errorMsg); // Show actual error message from backend
    } finally {
      setLoading(false);
    }
  };

  // Handle form resend otp
  const handleResendOtp = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await AuthServices.resendOtp({ tokenOfUser: userToken });
      if (response.status === true) {
        setOtpSent(true); 
        setUserToken(response.data.token); // Update token with the new one
      } else {
        setError(response.message || 'Failed to resend OTP. Please try again.'); 
      }
    } catch (error) {
      const errorMsg = error?.response?.data?.message || 'An unexpected error occurred. Please try again.';
      setError(errorMsg); // Show actual error message from backend
    } finally {
      setLoading(false);
    }
  };

  // Handle OTP verification
  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // Verify OTP using the most recent token (from either registration or resend OTP)
      const data = await AuthServices.verifyregister({ tokenOfUser: userToken, otp, fcmToken: 'fcmTokenValue' });
      
      if (data.status === true) {
        localStorage.setItem('authToken', data?.token);
        localStorage.setItem('userId', data?.user?._id);
        localStorage.setItem('userRole', data?.user?.userType);
        localStorage.setItem('name', data?.user?.name);
        localStorage.setItem('userId', data?.user?.id);
        localStorage.setItem('city', data?.user?.city);
        localStorage.setItem('mobileNo', data?.user?.mobileNo);
        localStorage.setItem('image', data?.user?.image);
        // On successful verification, store user data using the context's login function
        // login({
        //   name: data?.data?.name,
        //   image: data?.data?.image,
        //   token: data?.token, // Save token in the context for further authentication
        // });

        // Redirect to the home page or dashboard
        navigate('/');
      } else {
        setError(data.message || 'Invalid OTP. Please try again.'); // Show error from backend
      }
    } catch (error) {
      const errorMsg = error?.response?.data?.message || 'An unexpected error occurred. Please try again.';
      setError(errorMsg); // Show actual error message from backend
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dashboard-body">
      <div className="login-container">
        <div className="login-left">
          <img
            src="img/login-img.svg"
            alt="Login"
            className="login-img"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </div>
        <div className="login-right">
          <form id="login-form" onSubmit={otpSent ? handleVerifyOtp : handleSubmit}>
            <h2>Winja Travel</h2>
            <div className="form-group">
              <label htmlFor="name" className="icon-container">
                <i className="fa fa-user" />
              </label>
              <input
                type="text"
                name="name"
                className="input-field-signup"
                placeholder="Enter your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                disabled={otpSent} // Disable fields after OTP is sent
              />
            </div>
            <div className="form-group">
              <label htmlFor="mobileNo" className="icon-container">
                <i className="fa fa-phone" />
              </label>
              <input
                type="text"
                id="mobileNo"
                name="mobileNo"
                className="input-field-signup"
                placeholder="Enter your Mobile No."
                maxLength={10}
                value={mobileNo}
                onChange={(e) => setMobileNo(e.target.value)}
                required
                disabled={otpSent}
              />
            </div>
            <div className="form-group">
              <label htmlFor="city" className="icon-container">
                <i className="fa fa-building" />
              </label>
              <input
                type="text"
                id="city"
                name="city"
                className="input-field-signup"
                placeholder="Enter your City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
                disabled={otpSent}
              />
            </div>

            {otpSent && (
              <>
                <div className="form-group">
                  <label htmlFor="otp" className="icon-container">
                    <i className="fa fa-key" />
                  </label>
                  <input
                    type="text"
                    id="password"
                    name="otp"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>
                <div className="resend-otp-button">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={handleResendOtp} // Updated function here
                    disabled={loading}
                  >
                    Resend OTP
                  </button>
                </div>
              </>
            )}

            <div className="text-center">
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <button type="submit" className="button-login-btn" disabled={loading}>
                {loading ? 'Processing...' : otpSent ? 'Verify OTP' : 'Sign Up'}
              </button>
            </div>
            <p className="text-center">
              Already have an account? <Link to="/login">Sign In</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUP;
