import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import HomesliderServices from '../services/HomeSliderServices';
import useAsync from '../hooks/useAsync';
import SelectSliderSequence from './TogelButton/SelectSliderSequence';
import HelpTogal from './TogelButton/HelpTogal';
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import BannerUpdate from './Update Model/UpdateBanner';
import Pagetitle from './PageTitle';
Modal.setAppElement('#root');
function Banner() {
  const { data, error, isLoading, run } = useAsync(HomesliderServices.getBanner);
  // console.log(data)
  const count = data?.data?.length
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);

  const handleEditDetails = (slider) => {
    setSelectedEdit(slider);
    setIsEditModalOpen(true);
  };

  const handleDelete = (slider) => {
    setSelectedEdit(slider);
    setIsDeleteModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };


  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
  };
  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return formattedDate;

  }
  return (
    <>
      <div className="right_col" role="main">
        <Pagetitle></Pagetitle>
        <div className="container-box px-0">
          <div className="container-box-top-header justify-content-end">
            <div className="sub-title-box-right"  >
              <Link  className="Add-new-btn site-btn-green" style={{backgroundColor:"#FF7029"}} to="/admin/add-banner">
                <img src="img/plus-circle.svg" className='me-2' alt='' 
                />Add New
              </Link>
            </div>
          </div>
          <div className="container-box-inner">
            <table id="example" className="table table-striped" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>BANNER IMAGE</th>
                  <th>TITLE</th>
                  <th>SEQUENCE</th>
                  <th>CREATE DATE</th>
                  <th>STATUS</th>
                  <th>EDIT</th>
                  <th>DELETE</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((slider, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>

                      {/* <img src={process.env.REACT_APP_URL + slider.image} alt="" /> */}
                      <img src={process.env.REACT_APP_URL + slider?.image} alt="" style={{ height: '70px', width: '70px', objectFit: 'contain' }} />

                    </td>
                    <td>{slider.title}</td>
                    <td>
                      <SelectSliderSequence data={slider} page='banner' onSuccess={() => run()} />
                    </td>
                    <td>{formatDateTime(slider.createdAt)}</td>
                    <td className="status-toggle">
                      <HelpTogal help={slider} page='Banner' onSuccess={() => run()} />
                    </td>
                    <td>
                      <button className="view-details-btn edit-btn" onClick={() => handleEditDetails(slider)}><FontAwesomeIcon icon={faEdit} /></button>
                    </td>
                    <td>
                      <button className="view-details-btn delete-btn" onClick={() => handleDelete(slider)}><FontAwesomeIcon icon={faTrash} /></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <BannerUpdate banner={selectedEdit} closeModal={closeEditModal} onSuccess={run} />
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEdit} page="Banner" closeModal={closeDeleteModal} onSuccess={run} />
        </Modal>
      </div>

    </>
  )
}

export default Banner