// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .sub-link {
  color: #677788;
  text-decoration: none;
  padding: 5px;
  display: block;
  background-color: #c7ceed;
}
  
  .sub-link:hover, .sub-link.active {
    color: #223973;
  }
  
  .sub-link i {
    margin-right: 5px;
  }

  .user-permission-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .user-permission-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-permission-table th,
  .user-permission-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .user-permission-table th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  .user-permission-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .user-permission-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .save-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .save-button:hover {
    background-color: #45a049;
  }
  
   */`, "",{"version":3,"sources":["webpack://./src/component/Header/Css/sideBar.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;IAyEI","sourcesContent":["/* .sub-link {\n  color: #677788;\n  text-decoration: none;\n  padding: 5px;\n  display: block;\n  background-color: #c7ceed;\n}\n  \n  .sub-link:hover, .sub-link.active {\n    color: #223973;\n  }\n  \n  .sub-link i {\n    margin-right: 5px;\n  }\n\n  .user-permission-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  h2 {\n    text-align: center;\n    margin-bottom: 20px;\n    color: #333;\n  }\n  \n  .user-permission-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .user-permission-table th,\n  .user-permission-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: center;\n  }\n  \n  .user-permission-table th {\n    background-color: #f2f2f2;\n    color: #333;\n  }\n  \n  .user-permission-table tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  \n  .user-permission-table tr:hover {\n    background-color: #f1f1f1;\n  }\n  \n  .save-button {\n    display: block;\n    width: 100%;\n    padding: 10px;\n    margin-top: 20px;\n    background-color: #4CAF50;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 16px;\n  }\n  \n  .save-button:hover {\n    background-color: #45a049;\n  }\n  \n   */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
