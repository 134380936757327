import React from 'react'
import "../Header/Css/BookingDetails.css";

function ViewUser({ user, closeModal }) {
  // console.log(">>",user)
  if (!user) return null;
  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return { date: formattedDate, time: formattedTime };
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
};
  const url = 'uploads/'
  const { date, time } = formatDateTime(user.createdAt);

   // Sanitize or validate the HTML content before using dangerouslySetInnerHTML
   const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };
  return (
        <div className="modal fade viewbox edit-box show d-block" id="manufacturerModal" tabIndex={-1} aria-labelledby="manufacturerModal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="manufacturerModal"> Booking Details</h5>
            <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
          </div>
          <div className="modal-body px-5">
            <div className="d-box ">
              <ul className="d-flex list-unstyled flex-wrap justify-content-between booking-details-2 first-ul">
                <li><span>packageName</span>{user?.package?.packageName}</li>
                <li><span>Duration: </span>{user?.package?.duration}</li>
                <li><span>StartDate: </span>{formatDate(user?.package?.startDate)}</li>
                <li><span>EndDate: </span>{formatDate(user?.package?.endDate)}</li>
              </ul>

              <ul className="d-flex list-unstyled flex-wrap justify-content-between booking-details-2 second-ul">
                <li><span>Booking for</span> {user?.bookingFor}</li>
                <li><span>Contact Information</span> {user?.contact?.name}</li>
                <li><span>Email</span> {user?.contact?.email}</li>
                <li><span>Mobile</span> {user?.contact?.mobileNo}</li>
                <li><span>City</span> {user?.contact?.city}</li>
                <li><span>State</span> {user?.contact?.state}</li>
                <li><span>Address</span> {user?.contact?.address}</li>
              </ul>

              
              <ul className="d-flex list-unstyled flex-wrap justify-content-between booking-details-2 schedule-day">
              {user?.passengers?.map((member) => (
                <li key={member._id}>
                <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                  <li><span>Name</span> {member?.name}</li>
                  <li><span>Age</span> {member?.age}</li>
                  <li><span>Gender</span> {member?.gender}</li>
                  <li><span>City</span> {member?.city}</li>
                </ul>
              </li>
              ))}
              </ul>

              <ul className="list-unstyled booking-details-2">
                <li><span>Package Inclusion</span> 
                <div dangerouslySetInnerHTML={createMarkup(user?.package?.packageInclusions)} />
                </li>
                
              </ul>
              <ul className="list-unstyled booking-details-2">
                <li><span>Package Exclusion :</span> 
                <div dangerouslySetInnerHTML={createMarkup(user?.package?.packageExclusions)} />
                </li>
                
              </ul>
              <ul className="list-unstyled booking-details-2">
                <li><span>Package Policy :</span>
                {/* dangerouslySetInnerHTML= {createMarkup(user?.package?.policy)} */}
                <div dangerouslySetInnerHTML={createMarkup(user?.package?.policy)} />
                </li>
                {/* <h5>Package Policy</h5>
                <div dangerouslySetInnerHTML={createMarkup(user?.package?.policy)} /> */}
                
              </ul>


              <ul className="d-flex list-unstyled flex-wrap justify-content-between booking-details-2 second-ul schedule-day">
              {user?.package?.schedule?.map((member) => (
                <li key={member._id}>
                <ul className="d-flex list-unstyled flex-wrap justify-content-between">
                  <li><span>Day</span> {member?.day}</li>
                  <li><span>Transfer</span> {member?.transport?.from} To {member?.transport?.to}</li>
                  <li><span>check In hotel</span> {member?.hotel?.checkIn}</li>
                  <li><span>check Out hotel</span> {member?.hotel?.checkOut}</li>
                  <li><span>Hotel Stay</span> {member?.hotel?.name}</li>
                  <li><span>Location</span> {member?.hotel?.location}</li>
                  <li><span>Room Details</span> {member?.hotel?.roomType}</li>
                  <li><span>date</span> {formatDate(member?.date)}</li>
                </ul>
              </li>
              ))}
              </ul>

            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewUser


// import React from 'react';
// import "../Header/Css/BookingDetails.css";
// function ViewUser({ user, closeModal }) {
//   if (!user) return null;

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     if (isNaN(date.getTime())) return 'Invalid Date';

//     const day = date.getDate().toString().padStart(2, '0');
//     const month = (date.getMonth() + 1).toString().padStart(2, '0');
//     const year = date.getFullYear().toString();

//     return `${day}-${month}-${year}`;
// };
//   const url = 'uploads/';
//   const { date, time } = formatDate(user.createdAt);


 
//     return (
//       <div className="booking-details-container">
//         <h2>Booking Details</h2>
//         <div className="booking-header">
//           <div className="trip-details">
//             <h3>Fun Road Trip to Mussoorie to Nainital</h3>
//             <p>2N Mussoorie | 2N Nainital</p>
//           </div>
//           <div className="trip-info">
//             <p>
//               <strong>Start Date:</strong> 29/08/2024
//             </p>
//             <p>
//               <strong>End Date:</strong> 01/09/2024
//             </p>
//             <p>
//               <strong>Travellers:</strong> 2 Adults / From New Delhi
//             </p>
//           </div>
//         </div>
  
//         {/* Traveller Details */}
//         <div className="traveller-section">
//           <h4>1/4 Traveller Details</h4>
//           <div className="traveller-info">
//             <p><strong>Booking For:</strong> Self</p>
//             <p><strong>Email Id:</strong> john.doe@gmail.com</p>
//             <p><strong>Mobile:</strong> +91 1234569874</p>
//             <p><strong>City:</strong> Jaipur</p>
//             <p><strong>State:</strong> Rajasthan</p>
//           </div>
//         </div>
  
//         {/* Day 1 */}
//         <div className="day-details">
//           <h4>2/4 Traveller Details - Day 1</h4>
//           <div className="day-info">
//             <p><strong>Transfer:</strong> Arrival in Mussoorie from New Delhi via Private AC Sedan</p>
//             <p><strong>Hotel Stay:</strong> SRS LAKSHYA - Holiday Selection</p>
//             <p><strong>Location:</strong> Jagjeetpur</p>
//             <p><strong>Room Details:</strong> Deluxe Room - Holidays Selections x1</p>
//             <p><strong>Check-in to Hotel:</strong> 09 Aug 2024</p>
//           </div>
//         </div>
  
//         {/* Cancellation Policy */}
//         <div className="cancellation-section">
//           <h4>3/4 Cancellation & Date Change Policy</h4>
//           <div className="cancellation-info">
//             <p><strong>Package Cancellation Policy:</strong> Till 31 July 2024</p>
//             <p>Cancellation Fee: ₹1500 after 31 July 2024</p>
//             <p>Non-Refundable. Cancellation will not be allowed after the due date.</p>
//           </div>
//         </div>
//       </div>
//     );
//   };
  
//   export default ViewUser;
  
