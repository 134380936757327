import React, { useState, useEffect } from 'react';
import useAsync from '../../hooks/useAsync';
import { Link } from 'react-router-dom';
import HomesliderServices from '../../services/HomeSliderServices';
import PackageServices from '../../services/PackageServices';
import WishlistServices from '../../services/WishlistServices';

function Home({ searchValue }) {
  const { data: bannerData, error: bannerError, isLoading: bannerLoading } = useAsync(HomesliderServices.getWebBanner);
  const { data: packageData, error: packageError, isLoading: packageLoading } = useAsync(PackageServices.getWebPackage);

  const offers = packageData?.data || [];
  const banners = bannerData?.data || [];
  // console.log("searchValue", searchValue);


  // State to manage the visibility of all offers and banners
  const [showAllBanners, setShowAllBanners] = useState(false);
  const [showAllOffers, setShowAllOffers] = useState(false);

  // State to manage wishlist status for each package by id

  // Handle the "View All" button click for banners
  const handleViewAllBanners = (event) => {
    event.preventDefault(); // Prevent the default link behavior
    setShowAllBanners(prevState => !prevState); // Toggle between showing all banners and showing a few
  };
  const [authToken, setAuthToken] = useState(null);
  const [wishlistedPackages, setWishlistedPackages] = useState({});

  // Fetch wishlisted packages on component mount
  useEffect(() => {
    const fetchWishlistedPackages = async () => {
      try {
        const token = localStorage.getItem('authToken'); // Assuming token is stored in localStorage
        setAuthToken(token);
        const response = await WishlistServices.getWishlist(token);
        // console.log(">>>",response) // API to get wishlisted packages
        const wishlistedPackageIds = response.data.map((item) => item._id); // Assuming the API response contains a 'package' array with IDs

        // Convert array to an object with packageId as key and true as value
        const wishlistedPackagesMap = wishlistedPackageIds.reduce((acc, id) => {
          acc[id] = true;
          return acc;
        }, {});

        setWishlistedPackages(wishlistedPackagesMap);
      } catch (error) {
        console.error('Failed to fetch wishlisted packages:', error);
      }
    };

    fetchWishlistedPackages();
  }, []);

  const handleWishlistClick = async (e, packageId) => {
    e.preventDefault();

    try {
      if (wishlistedPackages[packageId]) {
        // If package is already in wishlist, remove it
        await WishlistServices.removeFromWishlist({ packageId });
      } else {
        // If package is not in wishlist, add it
        await WishlistServices.addToWishlist({ packageId });
      }

      // Toggle the wishlisted state
      setWishlistedPackages((prevWishlistedPackages) => ({
        ...prevWishlistedPackages,
        [packageId]: !prevWishlistedPackages[packageId],
      }));
    } catch (error) {
      console.error('Failed to update wishlist:', error);
    }
  };
  // Filter offers based on searchValue (if provided)
  const filteredOffers = searchValue
    ? offers.filter((offer) => {
      // console.log("Offer being checked:", offer);
      // console.log("Package name:", offer.packageName);
      return offer.packageName?.toLowerCase().includes(searchValue.toLowerCase());
    })
    : offers;

  // console.log("Filtered offers:", filteredOffers);


  // Determine which offers and banners to show
  // const displayedOffers = showAllOffers ? offers : offers.slice(0, 3);
  const displayedOffers = filteredOffers.slice(0, 3);
  const displayedBanners = showAllBanners ? banners : banners.slice(0, 3);

  return (
    <>
      <section className="popular-package-section welcome-section">
        <div className="container">
          <div className="heading-outer d-flex">
            <div className="main-heading-outer">
              <h3 className="main-heading">Popular Package</h3>
            </div>
            <div className="view-all-btn">
              <Link to="/tour-package">View All</Link>
            </div>
          </div>
          <div className="row">
            {displayedOffers.length > 0 ? (
              displayedOffers.map((packageItem) => (
                <div className="col-md-4" key={packageItem._id}>
                  <div className="offer-card">
                    <Link to={`/package-details/${packageItem._id}`} className="card-link">
                      <div className="card-img1-container" style={{ position: 'relative' }}>
                        <img
                          src={`${process.env.REACT_APP_URL || ''}${packageItem.images[0]}`}
                          className="card-img-top"
                          alt=""
                        />
                        {/* Wishlist icon */}
                        <i
                          className="wishlist-icon fa fa-heart"
                          style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            color: wishlistedPackages[packageItem._id] ? 'red' : 'grey', // Red if wishlisted, grey if not
                            fontSize: '24px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => handleWishlistClick(e, packageItem._id)} // Pass the specific package's id
                        ></i>
                      </div>
                    </Link>
                    <div className="card-body">
                      <Link to={`/package-details/${packageItem._id}`} className="card-link">
                        <h5 className="heading">{packageItem.packageName || "Package Title"}</h5>
                        <p className="sub-heading">Rate Rs <del>{packageItem.price}</del> </p>
                      </Link>
                      <p className="sub-heading-2">
                        {/* <Link to={`/tour-package`} className="card-link"> */}
                          Rate Rs {packageItem.discountPrice}/ <span className="sub-heading me-3">Onwards</span>
                        {/* </Link> */}
                        <span className="package-day">{packageItem.duration || "Duration"}</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No packages available</p>
            )}
          </div>

          {/* <div className="row">
            {filteredOffers.length > 0 ? (
              displayedOffers.map((packageItem) => (
                <div className="col-md-4" key={packageItem._id}>
                  <div className="offer-card">
                    <Link to={`/tour-package`} className="card-link">
                      <div className="card-img1-container" style={{ position: 'relative' }}>
                        <img
                          src={`${process.env.REACT_APP_URL || ''}${packageItem.images[0]}`}
                          className="card-img-top"
                          alt=""
                        />
                        <i
                          className="wishlist-icon fa fa-heart"
                          style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            color: wishlistedPackages[packageItem._id] ? 'red' : 'grey', // Red if wishlisted, grey if not
                            fontSize: '24px',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => handleWishlistClick(e, packageItem._id)} // Pass the specific package's id
                        ></i>
                      </div>
                    </Link>
                    <div className="card-body">
                      <Link to={`/tour-package`} className="card-link">
                        <h5 className="heading">{packageItem.packageName || "Package Title"}</h5>
                        <p className="sub-heading">Rate {packageItem.price || "Rate"}/</p>
                      </Link>
                      <p className="sub-heading-2">
                        <Link to={`/tour-package`} className="card-link">
                          Rate {packageItem.discountPrice || "Rate"} <span>Onwards</span>
                        </Link>
                        <span className="package-day">{packageItem.duration || "Duration"}</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No packages available</p>
            )}
          </div> */}
        </div>
      </section>

      <section className="welcome-offer-section welcome-section">
        <div className="container">
          <div className="heading-outer d-flex">
            <div className="main-heading-outer">
              <h3 className="main-heading">Welcome Offer</h3>
            </div>
            <div className="view-all-btn">
              <a href="#" onClick={handleViewAllBanners}>
                {showAllBanners ? 'View Less' : 'View More'}
              </a>
            </div>
          </div>
          <div className="row">
            {banners.length > 0 ? (
              displayedBanners.map((banner) => (
                <div className="col-lg-4 col-md-6" key={banner._id}>
                  <div className="offer-card">
                    <a href={banner.link} target="_blank" rel="noopener noreferrer">
                      <img
                        src={`${process.env.REACT_APP_URL || ''}${banner.image}`}
                        className="card-img-top"
                        alt={banner.title || 'Banner image'}
                      />
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <p>No offers available</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
