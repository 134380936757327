import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import UserServices from '../../services/UserServices';
import Pagetitle from '../../component/PageTitle';

function AddAdmin() {
  const [formValues, setFormValues] = useState({
    name: '',
    role: '',
    email: '',
    mobileNo: '',
    password: '',
  });



  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await UserServices.createAdmin(formValues);
      alert('Admin user created successfully');
    } catch (error) {
      console.error('Failed to create admin user', error);
    }
  };

  return (
    <div className="right_col" role="main">
    <Pagetitle></Pagetitle>
      <div className="container-box">
      <div className="container-box-top-header justify-content-end">
          <div className="sub-title-box-right">
          <Link className="site-btn-green" to="/admin-user">Admin User List</Link>
          </div>
        </div>
        <div className="container-box-inner px-4">
          <div className="row">
            <div className="col-md-12">
              <div className="page-details">
                <form onSubmit={handleSubmit}>
                <div className='row'>
                <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>User Type</label>
                    <select
                      className="form-control"
                      value={formValues.role}
                      onChange={handleInputChange}
                      name="role"
                    >
                      <option value="">--Select Type--</option>
                      <option value="User">User</option>
                      <option value="SubAdmin">Sub Admin</option>
                    </select>
                  </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formValues.name}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  </div>
                  
                 
                  <div className="col-lg-4 col-md-6">
                  <div className="input-field">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formValues.email}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                  <div className="input-field">
                    <label>Mobile No.</label>
                    <input
                      type="text"
                      name="mobileNo"
                      value={formValues.mobileNo}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  </div>
                  
                  <div className="col-lg-6 col-md-6">
                  <div className="input-field">
                    <label>City</label>
                    <input
                      type="text"
                      name="city"
                      value={formValues.city}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                  <div className="input-field">
                    <label>Permission</label>
                    <select
                      className="form-control"
                      value={formValues.role}
                      onChange={handleInputChange}
                      name="role"
                    >
                      <option value="">--Select Permission--</option>
                      <option value="UserManager">User Manager</option>
                      <option value="AppBanner">App Banner</option>
                      <option value="Pages">Pages</option>
                      <option value="Notification">Notification</option>
                      <option value="SubscriptionPlan">Subscription Plan</option>
                      <option value="SubscriptionUser">Subscription User</option>
                      <option value="Measurement">Unit of Measurement</option>
                      <option value="MeasurementTypes">Measurement Types</option>
                      <option value="Settings">Settings</option>
                    </select>
                  </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                  <div className="input-field">
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      value={formValues.password}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  </div>
                  </div>
                  <button className="site-btn-green">SUBMIT</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAdmin;
