import React, { useState } from 'react';
import useAsync from '../../hooks/useAsync';
import { Link } from 'react-router-dom';
import ContactServices from '../../services/ContactServices';
import Footer from './Common/footer'; 

function ContactUs() {
  const { data, error, isLoading } = useAsync(ContactServices.getContactDetail);
  // console.log(">>>", data);

  return (
    <>
      <section className="notification-section contact-us-section">
        <div className="container">
          <div className="tour-package-outer">
            <h3 className="banner-heading">Contact us</h3>
          </div>
        </div>
      </section>

      <section className="all-package-section">
        <div className="container">
          <div className="breadcrumb-outer">
            <ul className="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Contact us</li>
            </ul>
          </div>

          <div className="heading-outer d-flex">
            <div className="main-heading-outer">
              <h3 className="main-heading">Contact us</h3>
            </div>
            <div className="view-all-btn">
              {/* Empty anchor removed */}
            </div>
          </div>

          <div className="notification-inner">
            <h2 className="heading contact-heading" style={{ textAlign: "center" }}>
              Get in touch
            </h2>
            <p className="sub-heading text-center mt-3 contactSubHeading">
            Welcome to Winja Travel! We offer a variety of travel packages, whether you’re looking to explore stunning destinations, embark on adventures, or relax in luxurious settings. Let us make your travel dreams a reality.
            </p>

            <div className="get-touch-inner">
              <div className="row mt-5 gy-3">
                <div className="col-md-4">
                  <div className="get-box">
                    <div className="get-icon-box">
                      <img src="img/call-img.svg" alt="call-icon" />
                    </div>
                    <div className="get-content">
                      <h2 className="sub-heading text-center" style={{ color: "#24BAEC" , fontSize: "18px" }}>
                        Call Us:
                      </h2>
                      <a href={`tel:${data?.data?.phone}`}>
                        <p className="contact-p" style={{ color: "#1B1E28", textAlign: "center" }}>
                          {data?.data?.phone}
                        </p>
                      </a>

                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="get-box">
                    <div className="get-icon-box">
                      <img src="img/email-img.svg" alt="email-icon" />
                    </div>
                    <div className="get-content">
                      <h2 className="sub-heading text-center" style={{ color: "#24BAEC" , fontSize: "18px"}}>
                        Email Us:
                      </h2>
                      <a href={`mailto:${data?.data?.email}`}>
                        <p className="contact-p" style={{ color: "#1B1E28", textAlign: "center" }}>
                          {data?.data?.email}
                        </p>
                      </a>

                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="get-box">
                    <div className="get-icon-box">
                      <img src="img/whatsapp-img.svg" alt="whatsapp-icon" />
                    </div>
                    <div className="get-content">
                      <h2 className="sub-heading text-center" style={{ color: "#24BAEC", fontSize: "18px" }}>
                        Whatsapp
                      </h2>
                      <a href={`https://wa.me/${data?.data?.whatsApp}`} target="_blank" rel="noopener noreferrer">
                        <p className="contact-p" style={{ color: "#1B1E28", textAlign: "center" }}>
                          {data?.data?.whatsApp}
                        </p>
                      </a>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer component */}
      <Footer />
    </>
  );
}

export default ContactUs;
