// FrontendLayout.js
import React from 'react';
import FrontEndHeader from '../component/FrontEnd/Common/header';
import FrontendFooter from '../component/FrontEnd/Common/footer';
import { Outlet } from 'react-router-dom';


const FrontendLayout = ({ children ,onSearch }) => {
  return (
    <>
      <div className=" body">
      <div className="content">
      <FrontEndHeader onSearch = {onSearch} />

      
        <Outlet /> {/* This will render nested routes */}
     
        <FrontendFooter />
      </div>
      </div>
    </>
  );
};

export default FrontendLayout;
