import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import HomesliderServices from '../services/HomeSliderServices';
import useAsync from '../hooks/useAsync';
import SelectSliderSequence from './TogelButton/SelectSliderSequence';
import HelpTogal from './TogelButton/HelpTogal';
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import HomeSliderUpdate from './Update Model/HomeSliderUpdate';
Modal.setAppElement('#root');
function HomeSlider() {
  const {data,error,isLoading,run} = useAsync(HomesliderServices.getHomeSlider);
  console.log(data)
  const count = data?.data?.length
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);

  const handleEditDetails = (slider) => {
    setSelectedEdit(slider);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };

  const handleDelete = (slider) => {
    setSelectedEdit(slider);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };


  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
  };
  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return formattedDate;

  }
  return (
    <>
    <div className="right_col" role="main">
        <div className="title-box">
          <h2>Home Slider Banner List <span className="badge bg-orange">{count}</span></h2>
          <div className="container-box-top-header-right">
            <Link className="round-add-btn" to="/add-home-slider"><img src="img/plus.svg" alt="" /></Link>
          </div>
        </div>
        <div className="container-box px-0 pt-2">
          <div className="container-box-inner">
            <table id="example" className="table table-striped" style={{width: '100%'}}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Tittle</th>
                  <th>IMAGE</th>
                  <th>Date</th>
                  <th>User</th>
                  <th>sequence</th>
                  <th>STATUS</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((slider,i) => (
                  <tr key={i}>
                  <td>{i+1}</td>
                  <td>{slider.title}</td>
                  <td>
                    <div className="product-img">
                      <img src={process.env.REACT_APP_URL + slider.image} alt="" />
                    </div>
                  </td>
                  <td>{formatDateTime(slider.createdAt)}</td>
                  <td>{slider.userFor} </td>
                  <td>
                    <SelectSliderSequence data={slider} onSuccess={() => run()} />
                  </td>
                  <td className="status-toggle">
                    <HelpTogal help={slider} page='HomeSlider' onSuccess={() => run()} />
                  </td>
                  <td>
                  <div className="action_toggle_outer">
                        <button
                          className="action_toggle"
                          onClick={() => toggleActionMenu(i)}
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        {activeIndex === i && (
                          <div className="action_toggle_content product-edit show d-block">
                            <ul className="list-unstyled">
                              <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                              <li><button className="edit" onClick={() => handleEditDetails(slider)} >Edit</button></li>
                              <li><button className="reject" onClick={() => handleDelete(slider)} >Delete</button></li>
                            </ul>
                          </div>
                        )}
                      </div>
                  </td>
                </tr>
                ))}
                
               
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <HomeSliderUpdate notification={selectedEdit} closeModal={closeEditModal} onSuccess={run}/>
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEdit} page="HomeSlider" closeModal={closeDeleteModal}  onSuccess={run}/>
        </Modal>
      </div>
      
      </>
  )
}

export default HomeSlider