import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAsync from '../../../hooks/useAsync';
import DestinationServices from '../../../services/DestinationServices';
import AuthServices from '../../../services/AuthServices';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Header({onSearch}) {
  const { data, error: fetchError, isLoading: isFetching } = useAsync(DestinationServices.getWebDestination);
  const destinations = data?.data || [];
  const [search, setSearch] = useState("");


  const [name, setName] = useState('');
  const [mobileNo, setMobileNo] = useState('');

  // const [profileImage, setProfileImage] = useState(formValues.image);

  const [address, setAddress] = useState('');
  const [date, setDate] = useState('');
  const [categoryService, setCategoryService] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [userToken, setUserToken] = useState('');


  const username = localStorage.getItem("name");
  const image = localStorage.getItem("image");

  const navigate = useNavigate();

  useEffect(() => {
    // Simulate fetching user data from localStorage
    setName(localStorage.getItem('name'));
  }, []);

  const [previewImage, setPreviewImage] = useState('img/edit-profile.svg');


  const [formValues, setFormValues] = useState({
    name: '',
    city: '',
    mobileNo: '',
    image: '',
  });
  const [authToken, setAuthToken] = useState(null);

  // Fetch profile data using the getMyProfile API with authToken
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const token = localStorage.getItem('authToken'); // Assuming token is stored in localStorage
        setAuthToken(token);
        const response = await AuthServices.getMyProfile(token); // Pass the token to getMyProfile
        if (response.data) {
          const profileData = response.data;
          setFormValues({
            name: profileData.name || '',
            city: profileData.city || '',
            mobileNo: profileData.mobileNo || '',
            image: profileData.image || '',
          });

          // Set preview image if the image exists
          if (profileData.image) {
            setPreviewImage(process.env.REACT_APP_URL + profileData.image);
          }
        }
      } catch (error) {
        console.error('Failed to fetch profile data:', error);
      }
    };

    fetchProfileData();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result); // Show the uploaded image preview
      };
      reader.readAsDataURL(file);

      // Update the formValues with the file object
      setFormValues({
        ...formValues,
        image: file, // store the actual file object in formValues
      });
    }
  };
  const handleSearch = (e)=>{
 
    const { value } = e.target;
    setSearch(value);
    onSearch(value)
  };

  const handleInputProfileChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Handle form submission
  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      // for (const key in formValues) {
      //   formData.append(key, formValues[key]);
      // }
      for (const key in formValues) {
        if (key !== 'image' || formValues[key] instanceof File) {
          formData.append(key, formValues[key]);
        }
      }
      // // Append the image file only if it's a File object
      // if (formValues.image instanceof File) {
      //   formData.append('image', formValues.image);
      // }

      console.log('FormData before update:', [...formData.entries()]);

      await AuthServices.updateMe(formData);
      alert('Profile details updated successfully');
    } catch (error) {
      console.error('Failed to update profile details', error);
      alert('Failed to update profile details');
    }
  };

  // Slider settings
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 11,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 481,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const logOut = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate('/');
    window.location.reload();
  }
  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   setProfileImage(URL.createObjectURL(file));
  //   setFormValues((prevValues) => ({
  //     ...prevValues,
  //     image: file,
  //   }));
  // };
  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setProfileImage(reader.result); // Preview the image
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  // Handle form submission to send OTP
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await AuthServices.register({
        name,
        mobileNo,

        referralCode,
        date,
        address,
        categoryService,
        userType: 'Vendor',
      });
      if (response.status === true) {
        setOtpSent(true);
        setUserToken(response.data.token);
      } else {
        setError(response.message || 'Failed to send OTP. Please try again.');
      }
    } catch (error) {
      setError(error?.response?.data?.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle OTP verification
  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await AuthServices.verifyregister({
        tokenOfUser: userToken,
        otp,
        fcmToken: 'fcmTokenValue',
      });

      if (response.status === true) {

        // On successful verification, open the thank you modal
        const modal = new window.bootstrap.Modal(document.getElementById('thanksModal'));
        modal.show();
        // navigate('/');
      } else {
        setError(response.message || 'Invalid OTP. Please try again.');
      }
    } catch (error) {
      setError(error?.response?.data?.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle OTP resend
  const handleResendOtp = async () => {
    setError('');
    setLoading(true);

    try {
      const response = await AuthServices.resendOtp({ tokenOfUser: userToken });
      if (response.status === true) {
        setUserToken(response.data.token);
      } else {
        setError(response.message || 'Failed to resend OTP. Please try again.');
      }
    } catch (error) {
      setError(error?.response?.data?.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Modal trigger for "Thank You" message
  const handleSignUp = (e) => {
    e.preventDefault();
    const modal = new window.bootstrap.Modal(document.getElementById('thanksModal'));
    modal.show();
  };

  return (
    <section className="header-section">
      <header>
        <div className="container">
          <div className="header-inner">
            <div className="row align-items-center">
              <div className="col-2">
                <div className="hero-logo">
                  <a href="/home">
                    <img src="img/logo.svg" alt="logo" />
                  </a>
                </div>
              </div>
              <div className="col-10">

                <div className="header-btn d-flex align-items-center justify-content-end">
                  {username ? (
                    // Show username and image if logged in
                    <>
                      <div className="user-info">
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#profileModal"
                        >
                          <img
                            // src={image || 'img/default-user.svg'}
                            src={previewImage}
                            alt="User"
                            className="img-fluid"
                            style={{ borderRadius: '50%', height: '40px', width: '40px', marginRight: '10px' }}
                          />
                          <span style={{ color: "white" }}>{formValues.name}</span>
                        </a>
                      </div>

                    </>
                  ) : (
                    // Show vendor registration and login buttons if not logged in
                    <>
                      <div className="brown-btn">
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#registrationModal"
                        >
                          <img src="img/vendor.svg" alt="Vendor" />
                          Vendor Registration
                        </a>
                      </div>
                      <div className="brown-btn orange-btn">
                        <Link to="/login">
                          <img src="img/create-account.svg" alt="Create Account" />
                          Login or Create Account
                        </Link>
                      </div>
                    </>
                  )}

                </div>
                {/* <div className="header-btn d-flex align-items-center justify-content-end">
                  <div className="brown-btn">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#registrationModal"
                    >
                      <img src="img/vendor.svg" alt="Vendor" />
                      Vendor Registration
                    </a>
                  </div>
                  <div className="brown-btn orange-btn">
                    <Link to="/login">
                      <img src="img/create-account.svg" alt="Create Account" />
                      Login or Create Account
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
            <h1>
              Explore the <br />
              Beautiful <span>world!</span>
            </h1>

            <div className="our-service-slider trip-outer">
              {destinations.length > 0 ? (
                <Slider {...sliderSettings}>
                  {destinations.map((destination) => (
                    <div className="our-service-slider-inner" key={destination._id}>
                      <div className="service-icon-box">
                      <Link to={`/destination-package/${destination._id}`}>
                        {destination?.image && (
                          <img
                            src={`${process.env.REACT_APP_URL || ''}${destination.image}`}
                            alt={destination?.title || 'destination image'}
                            style={{ height: '50px', width: '50px', objectFit: 'contain' }}
                          />
                        )}
                        </Link>
                      </div>
                      <Link to={`/destination-package/${destination._id}`}>
                      <h4 className="destination-heading">{destination.title}</h4>
                      </Link>
                    </div>
                  ))}
                </Slider>
              ) : (
                <p>No destinations available</p>
              )}
            </div>

            <div className="search-bar">
              <form className="search-bar-form">
                <input type="text" value={search} onChange={handleSearch} placeholder="Search Your Package" />
                <button type="submit" >Search</button>
              </form>
            </div>
          </div>
        </div>
      </header>

      {/* Registration Modal */}
      <div className="modal" id="registrationModal">
        <div className="modal-dialog modal-xl modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="login-container registration-container">
                <div className="login-left">
                  <img
                    src="img/registration-img.svg"
                    alt="Login Image"
                    className="login-img"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </div>
                <div className="login-right">
                  <form id="login-form" onSubmit={handleSubmit}>
                    <h2>Registration</h2>
                    <p className="registration-paragraph">
                      Welcome to Winjo Travel <br />
                      Please Register to Continue
                    </p>
                    <div className="form-group form-group-2">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter Your Name*"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group form-group-1">
                      <input
                        type="text"
                        id="Category"
                        name="mobile"
                        placeholder="Enter Mobile Number*"
                        value={mobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group form-group-1">
                      <input
                        type="text"
                        id="address"
                        name="address"
                        placeholder="Address*"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group form-group-1">
                      <input
                        type="date"
                        id="date"
                        name="date"
                        placeholder="Date Availability*"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group form-group-1">
                      <select
                        name="categoryService"
                        id="Category"
                        value={categoryService}
                        onChange={(e) => setCategoryService(e.target.value)}
                        required
                      >
                        <option value="">Category of Service</option>
                        <option value="hotel">Hotel</option>
                        <option value="travel">Travel</option>
                      </select>
                    </div>
                    <div className="form-group form-group-1">
                      <input
                        type="text"
                        id="Category"
                        name="code"
                        placeholder="Referral Code*"
                        value={referralCode}
                        onChange={(e) => setReferralCode(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="invalidCheck"
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="invalidCheck"
                      >
                        I agree to all <a href="/terms">Terms &amp; Conditions </a>
                      </label>
                    </div>

                    {otpSent ? (
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                        <button
                          className="brown-btn"
                          type="button"
                          onClick={handleVerifyOtp}
                          disabled={loading}
                        >
                          {loading ? 'Verifying...' : 'Verify OTP'}
                        </button>
                        <button
                          className="resend-btn"
                          type="button"
                          onClick={handleResendOtp}
                          disabled={loading}
                        >
                          Resend OTP
                        </button>
                      </div>
                    ) : (
                      <div className="text-center mt-3">
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <button type="submit" className="button-login-btn" disabled={loading}>
                          {loading ? 'Processing...' : otpSent ? 'Verify OTP' : 'Sign Up'}
                        </button>
                      </div>
                    )}

                    {error && <p className="error">{error}</p>}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Thank You Modal */}
      <div className="modal fade" id="thanksModal" tabIndex="-1" aria-labelledby="thanksModalLabel" aria-hidden="true">

        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body thankyou-modal-body text-center">
              <h2>Thank You for Your Registration!</h2>
              <p>Your registration has been successfully. Thanks!</p>
              <div className='text-center'>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* profile  Modal */}
      {/* <div className="modal" id="profileModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Profile
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="container">
              <form onClick={handleUpdate}>
                <div className="profile-outer">
                  <div
                    className="user-icon-outer d-flex"
                    style={{ alignItems: "baseline" }}
                  >
                    <div className="user-icon">
                      <img
                        src={profileImage || "img/edit-profile.svg"}
                        alt=""
                        id="profileImage"
                        onClick={() => document.getElementById("fileInput").click()}
                        style={{ cursor: "pointer" }}
                      />
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </div>
                    <div className="user-name">
                      <h5 className="heading" style={{ fontSize: 24 }}>
                        {username}
                      </h5>
                    </div>
                  </div>

                  <div className="profile-inner">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="booking-form" style={{ marginBottom: 20 }}>
                     
                            <label htmlFor="fname" style={{ marginBottom: 10 }}>
                              Name
                            </label>
                            <br />
                            <input
                              type="text"
                              id="fname"
                              name="name"
                              value={username}
                              placeholder="Akshay"
                            />
                        
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="booking-form">
                      
                            <label htmlFor="location" style={{ marginBottom: 10 }}>
                              Location
                            </label>
                            <br />
                            <input
                              type="text"
                              id="fname"
                              name="city"
                              value={usercity}
                              placeholder="Jaipur, Rajasthan"
                            />
                     
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="booking-form">
                     
                            <label htmlFor="mobile" style={{ marginBottom: 10 }}>
                              Mobile Number
                            </label>
                            <br />
                            <input
                              type="text"
                              id="fname"
                              name="mobileNo"
                              value={usermobileNo}
                              placeholder="+91"
                            />
                   
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer">
                    <button type="button" className="update-btn">
                      Update
                    </button>
                  </div>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="modal" id="profileModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Profile</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="container">
                <form onSubmit={handleUpdate}>
                  <div className="profile-outer">
                    <div className="col-md-4">
                      <div className="input-field">
                        <label>
                          Upload<span className="red">*</span>
                        </label>
                        <input
                          type="file"
                          name="image"
                          className="form-control"
                          onChange={handleFileChange}
                        />
                        <div className="file-preview text-center">
                          <img
                            style={{
                              verticalAlign: 'middle',
                              height: '100px',
                              borderRadius: '100px',
                              textAlign: 'left',
                            }}
                            id="uploadFile"
                            src={previewImage }
                            alt="your image"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="profile-inner">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="booking-form" style={{ marginBottom: 20 }}>
                            <label htmlFor="fname" style={{ marginBottom: 10 }}>
                              Name
                            </label>
                            <br />
                            <input
                              type="text"
                              id="fname"
                              name="name"
                              value={formValues.name}
                              onChange={handleInputChange}
                              placeholder="name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="booking-form">
                            <label htmlFor="location" style={{ marginBottom: 10 }}>
                              Location
                            </label>
                            <br />
                            <input
                              type="text"
                              id="fname"
                              name="city"
                              value={formValues.city}
                              onChange={handleInputChange}
                              placeholder="Jaipur, Rajasthan"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="booking-form">
                            <label htmlFor="mobile" style={{ marginBottom: 10 }}>
                              Mobile Number
                            </label>
                            <br />
                            <input
                              type="text"
                              id="fname"
                              name="mobileNo"
                              readOnly
                              value={formValues.mobileNo}
                              placeholder="+91 7878787878"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="footer">
                      <button type="submit" className="update-btn">
                        Update
                      </button>
                      <button
                        type="button"
                        className=" bg-danger update-btn float-end"
                        onClick={(e)=>logOut(e)}
                      >
                        Sign out
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>


    </section>
  );
}

export default Header;
