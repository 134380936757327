// import React, { useState, useEffect } from 'react';
// import useAsync from '../hooks/useAsync';
// import FeedbackServices from '../services/FeedbackServices';
// import Modal from 'react-modal';
// import DeleteButton from './Button/DeleteButton';
// import ViewFeedback from './View Model/ViewFeedback';
// import StarRating from './Rating/StarRating';

// Modal.setAppElement('#root');

// function Feedback() {
//   const { data, error, isLoading, run } = useAsync(FeedbackServices.getFeedback);
//   const [activeIndex, setActiveIndex] = useState(null);
//   const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
//   const [selectedEdit, setSelectedEdit] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredData, setFilteredData] = useState([]);

//   useEffect(() => {
//     if (data?.data) {
//       setFilteredData(data.data);
//     }
//   }, [data]);

//   useEffect(() => {
//     if (data?.data) {
//       setFilteredData(
//         data.data.filter(feedback =>
//           (feedback?.user?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           feedback?.message?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           feedback?.user?.mobileNo?.toString().includes(searchTerm))
//         )
//       );
//     }
//   }, [data, searchTerm]);

//   const handleEditDetails = (feedback) => {
//     setSelectedEdit(feedback);
//     setIsEditModalOpen(true);
//     toggleActionMenu(null);
//   };

//   const handleDelete = (feedback) => {
//     setSelectedEdit(feedback);
//     setIsDeleteModalOpen(true);
//     toggleActionMenu(null);
//   };

//   const closeEditModal = () => {
//     setIsEditModalOpen(false);
//     setSelectedEdit(null);
//   };

//   const closeDeleteModal = () => {
//     setIsDeleteModalOpen(false);
//     setSelectedEdit(null);
//   };

//   const toggleActionMenu = (index) => {
//     setActiveIndex(index === activeIndex ? null : index);
//   };

//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value);
//   };

//   function formatDateTime(isoString) {
//     const date = new Date(isoString);
//     return date.toLocaleDateString();
//   }

//   function truncateText(text, limit) {
//     const words = text.split(' ');
//     if (words.length > limit) {
//       return words.slice(0, limit).join(' ') + '...';
//     }
//     return text;
//   }

//   return (
//     <div className="right_col" role="main">
//       <div className="title-box">
//         <h2>Feedback List <span className="badge bg-orange">{filteredData?.length}</span></h2>
//       </div>
//       <div className="container-box px-0">
//         <div className="container-box-top-header px-4">
//           <div className="container-box-top-header-left-2">
//             <input 
//               type="search" 
//               name="search" 
//               placeholder="Search" 
//               value={searchTerm} 
//               onChange={handleSearchChange} 
//             />
//             <button className="search-btn">Search</button>
//           </div>
//         </div>
//         <div className="container-box-inner">
//           {isLoading ? (
//             <p>Loading...</p>
//           ) : error ? (
//             <p>{error.message}</p>
//           ) : (
//             <table id="example" className="table table-striped" style={{ width: '100%' }}>
//               <thead>
//                 <tr>
//                   <th>#</th>
//                   <th>User Name</th>
//                   <th className="w-50">Brief</th>
//                   <th>Mobile No</th>
//                   <th>Date</th>
//                   <th>Rating</th>
//                   <th>ACTIONS</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {filteredData.map((feedback, index) => (
//                   <tr key={index}>
//                     <td>{index + 1}</td>
//                     <td>{feedback?.user?.name}</td>
//                     <td className="address">{truncateText(feedback?.message, 20)}</td>
//                     <td>{feedback?.user?.mobileNo}</td>
//                     <td className="payment">{formatDateTime(feedback.createdAt)}</td>
//                     <td>
//                       <StarRating rating={feedback?.rating} />
//                     </td>
//                     <td>
//                       <div className="action_toggle_outer">
//                         <button
//                           className="action_toggle"
//                           onClick={() => toggleActionMenu(index)}
//                         >
//                           <i className="fa fa-ellipsis-v" />
//                         </button>
//                         {activeIndex === index && (
//                           <div className="action_toggle_content product-edit show d-block">
//                             <ul className="list-unstyled">
//                               <li>
//                                 <button className="close_toggle" onClick={() => toggleActionMenu(null)}>
//                                   <i className="fa fa-close" />
//                                 </button>
//                               </li>
//                               <li>
//                                 <button className="edit" onClick={() => handleEditDetails(feedback)}>View</button>
//                               </li>
//                               <li>
//                                 <button className="reject" onClick={() => handleDelete(feedback)}>Delete</button>
//                               </li>
//                             </ul>
//                           </div>
//                         )}
//                       </div>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           )}
//         </div>
//       </div>
//       <Modal
//         isOpen={isEditModalOpen}
//         onRequestClose={closeEditModal}
//         contentLabel="User Details"
//         className="modal-content"
//         overlayClassName="modal-overlay"
//       >
//         <ViewFeedback feedback={selectedEdit} closeModal={closeEditModal} onSuccess={run} />
//       </Modal>
//       <Modal
//         isOpen={isDeleteModalOpen}
//         onRequestClose={closeDeleteModal}
//         contentLabel="Delete Confirmation"
//         className="modal-content"
//         overlayClassName="modal-overlay"
//       >
//         <DeleteButton data={selectedEdit} page="feedback" closeModal={closeDeleteModal} onSuccess={run} />
//       </Modal>
//     </div>
//   );
// }

// export default Feedback;
