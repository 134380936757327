import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import BookServices from '../../services/BookingServies';
import PackageServices from '../../services/PackageServices';
import Footer from './Common/footer';
import axios from 'axios';

function Booking({ }) {
  const { id } = useParams();
  const decodedId = atob(id);
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();
  const [pkg, setPkg] = useState(null);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('authToken');
  const [bookingFor, setBookingFor] = useState('Myself'); // For 'Myself' or 'Someone else'
  const [refer, setRefer] = useState('');
  const [dateSelect, setDateSelect] = useState('');
  const [endDate, setEndDate] = useState('');
  const [children, setChildren] = useState('');
  const [cancelDate, setCancelDate] = useState('');
  const [passengers, setPassengers] = useState([
    { name: '', age: '', gender: '', city: '' },
    { name: '', age: '', gender: '', city: '' },
  ]); // Initial state with two default rows
  const [contact, setContact] = useState({
    name: '',
    mobileNo: '',
    email: '',
    city: '',
    state: '',
    address: '',
  });
  const [showForm, setShowForm] = useState(false); // Toggle form visibility
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    const fetchPackage = async () => {
      try {
        const response = await PackageServices.getPackageById(decodedId);
        setPkg(response.data); // Set the package details to `pkg`
      } catch (err) {
        setError('Error loading package details');
      }
    };
    fetchPackage();
  }, [decodedId]);
  const handlePassengerChange = (index, field, value) => {
    const newPassengers = [...passengers];
    newPassengers[index][field] = value;
    setPassengers(newPassengers);
  };

  const handleBookingForChange = (e) => {
    setBookingFor(e.target.value);
  };
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setDateSelect(selectedDate);
    calculateEndDate(selectedDate, pkg.duration);
    calculateCancelDate(selectedDate);

  };
  const calculateEndDate = (startDate, duration) => {
    // Extract the number of nights from the duration string
    const nights = parseInt(duration.split('N')[0], 10); // Get the number of nights
    if (!isNaN(nights) && startDate) {
      const start = new Date(startDate);
      const end = new Date(start);
      end.setDate(start.getDate() + nights); // Add nights to the start date
      setEndDate(formatDate(end)); // Format the end date
    }
  };
  const calculateCancelDate = (startDate) => {
    if (startDate) {
      const start = new Date(startDate);
      start.setDate(start.getDate() - 7); // Subtract 7 days
      setCancelDate(formatDate(start)); // Format the cancel date
    }
  };
  const handleReferChange = (e) => {
    setRefer(e.target.value);
  };
  const handleChildrenForChange = (e) => {
    setChildren(e.target.value);
  };

  const removePassenger = (index) => {

    if (index > 1) {
      const newPassengers = passengers.filter((_, i) => i !== index);
      setPassengers(newPassengers);
    }
  };
  const addPassenger = () => {
    setPassengers([...passengers, { name: '', age: '', gender: '', city: '' }]);
  };
  const handleContactChange = (field, value) => {
    setContact({ ...contact, [field]: value });
  };

  const getFilledPassengerCount = () => {
    return passengers.filter(passenger =>
      passenger.name && passenger.age && passenger.gender && passenger.city
    ).length;
  };
  const memberquantiy = getFilledPassengerCount();
  const calculateAmounts = () => {
    const filledPassengerCount = getFilledPassengerCount(); // Get the count of filled passengers
    const basePrice = pkg.discountPrice || 0;
    const totalPrice = basePrice * filledPassengerCount;
    const taxPercentage = pkg.tax || 0;
    const taxAmount = (totalPrice * taxPercentage) / 100;
    const totalAmount = totalPrice + taxAmount;
    return totalAmount;    // setFinalAmount(finalAmount);
  };

  const handleBookingSubmit = async () => {
    if (!pkg) {
      console.error('Package details are not available');
      return;
    }

    const underagePassenger = passengers.some(passenger => passenger.age < 18);
    if (underagePassenger) {
      alert('Age must be 18 or above');
      return;  // Stop further execution if validation fails
    }

    const finalAmount = calculateAmounts();

    // Check registration fees, if greater than 0, separate amount should be paid
    let paymentAmount = finalAmount;  // Default to total amount
    if (pkg.registrationPrice > 0) {
      paymentAmount = pkg.registrationPrice;  // Only registration fee if greater than 0
    }

    // Construct booking data with necessary fields
    const bookingData = {
      packageId: pkg._id,
      startDate: dateSelect,
      endDate: endDate,
      totalAmount: finalAmount,
      registrationFees: pkg.registrationPrice, // Use the calculated totalAmount (now the final amount)
      bookingFor,
      refer,
      departureDate: dateSelect,
      tax: pkg.tax, // Use the package's tax field
      quantity: memberquantiy, // Quantity of passengers
      passengers,
      contact,
      userId, // User ID from localStorage
      numberOfChildren: children
    };

    try {
      // Send booking data to the backend
      const response = await BookServices.createBooking(bookingData);

      const { razorpayOrderId, booking } = response.data;

      const options = {
        key: 'rzp_live_GSX6yECeAmVRxm', // Replace with your Razorpay Key ID
        amount: paymentAmount,  // Use the determined payment amount (registration fee or total amount)
        currency: "INR",
        name: 'Bag',
        description: 'Payment for your order',
        image: 'https://your-logo-url.com/logo.png',
        order_id: razorpayOrderId, // The order ID returned from the backend
        handler: async function (paymentResponse) {
          try {
            // Call verify API after successful payment
            const paymentVerification = await BookServices.verifyPayment({
              ...bookingData, // Spread booking data to include all necessary fields
              paidAmount: paymentAmount,
              paymentId: paymentResponse.razorpay_payment_id,
              signature: paymentResponse.razorpay_signature,
            });

            if (paymentVerification.status === true) {
              alert(' Payment Successful! Thank you for your order.');

              navigate('/order');
            } else {
              alert('Payment Failed. Please try again or use a different payment method.');
            }
          } catch (error) {
            alert('Payment verification error.');
            console.error('Error during verification:', error);
          }
        },
      };

      // Trigger Razorpay checkout
      const razorpay = new window.Razorpay(options);
      razorpay.open();



    } catch (error) {
      // Handle any errors during booking creation
      alert(error.response?.data?.message || "An error occurred while creating the booking.");
      console.error('Booking failed', error);
    }
  };

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  const formatDateMonth = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';

    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options); // "22 Oct, 2024"
  };

  const formatDay = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayOfWeek = daysOfWeek[date.getDay()];

    return `${dayOfWeek}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  };
  const formatEndDate = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    if (isNaN(date.getTime())) return 'Invalid Date';
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };
  const formatEndDateDay = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    if (isNaN(date.getTime())) return 'Invalid Date';
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayOfWeek = daysOfWeek[date.getDay()];

    return dayOfWeek;
  };
  if (error) return <div>{error}</div>;
  if (!pkg) return <div>Loading package details...</div>;
  return (
    <>
      <section className="notification-section booking-section">
        <div className="container">
          <div className="tour-package-outer">
            <h3 className="banner-heading">Booking</h3>
          </div>
        </div>
      </section>
      <section className="all-package-section">
        <div className="container">
          <div className="breadcrumb-outer">
            <ul className="breadcrumb">
              <li><a href="/">Home</a></li>
              <li> <a href="/tour-package" className="breadcrumb-li">  Tour Package</a></li>
              <li> <Link to={`/package-details/${pkg._id}`} className="breadcrumb-li">Package Details</Link> </li>
              <li>Booking</li>
            </ul>
          </div>
          <div className="heading-outer d-flex">
            <div className="main-heading-outer">
              <h3 className="main-heading">Booking</h3>
            </div>
            <div className="view-all-btn">
              <a href="#"> </a>
            </div>
          </div>
          <div className="notification-inner">
            <div className="row">
              <div className="col-lg-4">
                <h5 className="heading" style={{ marginBottom: 10 }}>
                  {pkg.packageName}
                </h5>
                <p className="sub-heading" style={{ marginBottom: 10 }}>
                  {pkg.startLocation} to {pkg.endLocation}
                </p>
              </div>
              <div className="col-lg-4" style={{ textAlign: "center" }}>
                <div className="booking-date-outer d-flex">
                  <div className="booking-outer-2" style={{ marginLeft: "100px" }}>
                    <label style={{ marginBottom: 10 }}> <strong>Departure Date</strong></label>
                    <input
                      className='form-control'
                      type="date"
                      name="departureDate"
                      onChange={handleDateChange}
                    />
                  </div>

                </div>
              </div>
              {dateSelect && (
                <div className="col-lg-4">
                  <div className="booking-date-outer d-flex">
                    <div className="booking-outer-2">
                      <h5 className="heading " style={{ fontSize: 16 }}>
                        {formatDateMonth(dateSelect)}
                      </h5>
                      <p className="sub-heading" style={{ marginBottom: 10 }}>
                        {formatDay(dateSelect)}
                      </p>
                    </div>
                    <div className="booking-outer-3">
                      <a href="#" className=" package-day" style={{ background: "rgba(108, 112, 114, 0.15)", color: "#6C7072", }}>{pkg.duration}</a>
                    </div>
                    <div className="booking-outer-2">
                      <h5 className="heading" style={{ fontSize: 16 }}>
                        {formatEndDate(endDate)}
                      </h5>
                      <p className="sub-heading" style={{ marginBottom: 10 }}>  {formatEndDateDay(endDate)} </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-lg-4">
                <div className="booking-date-outer">
                  <div className="booking-outer-2">
                  </div>
                </div>
              </div>
              <div className="day-plan-border" style={{ marginBlock: 15 }} />
              <div className="col-lg-4">
                <div className="booking-date-outer d-flex">
                  <div className="booking-outer-2">
                    <h5 className="heading" style={{ fontSize: 16, marginBottom: 10 }} >Traveller Details</h5>
                    <h5 className="heading" style={{ fontSize: 16 }}>
                      Travellers:
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="booking-date-outer d-flex">
                  <div className="booking-outer-2">
                    <h5 className="heading" style={{ fontSize: 16, marginBottom: 10 }}> Booking For </h5>
                    <div className="booking-choice d-flex">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="bookingFor"
                          value="Myself"
                          checked={bookingFor === 'Myself'}
                          onChange={handleBookingForChange}
                        />
                        <label className="form-check-label">
                          Myself
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="bookingFor"
                          value="Someone else"
                          onChange={handleBookingForChange}
                        />
                        <label className="form-check-label">
                          Someone else
                        </label>
                      </div>
                    </div>
                    <div className="change-btn Traveller-add-btn">
                      <button onClick={addPassenger} className="btn btn-primary">
                        Add Traveller
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* {showForm && ( */}
              <div className="col-lg-12">
                <div className="traveller-form">
                  {passengers.map((passenger, index) => (
                    <div key={index} className="row">
                      <div className="col-lg-3">
                        <input
                          type="text"
                          required
                          placeholder="Name"
                          className="form-control"
                          value={passenger.name}
                          onChange={(e) =>
                            handlePassengerChange(index, 'name', e.target.value)
                          }
                        />
                      </div>
                      <div className="col-lg-2">
                        <input
                          type="number"
                          maxLength={3}
                          required
                          placeholder="Age"
                          className="form-control"
                          value={passenger.age}
                          onChange={(e) =>
                            handlePassengerChange(index, 'age', e.target.value)
                          }
                        />
                      </div>
                      <div className="col-lg-2">
                        <select
                          required
                          className="form-control"
                          value={passenger.gender}
                          onChange={(e) =>
                            handlePassengerChange(index, 'gender', e.target.value)
                          }
                        >
                          <option value="">Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          required
                          placeholder="City"
                          className="form-control "
                          value={passenger.city}
                          onChange={(e) =>
                            handlePassengerChange(index, 'city', e.target.value)
                          }
                        />
                      </div>
                      <div className="col-lg-2">
                        <button
                          onClick={() => removePassenger(index)} // Call remove function
                          className="btn btn-danger"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* )} */}

              <div className="day-plan-border" style={{ marginBlock: 15 }} />
              <div className='row'>
                <div className="col-md-4">
                  <div className="booking-date-outer d-flex">
                    <div className="booking-outer-2">
                      <h5 className="heading" style={{ fontSize: 16, marginBottom: 10 }}> Children Details: </h5>
                      <div className="booking-choice d-flex">
                        <div >
                          <label style={{ marginBottom: 10 }}>Numbers Of Children</label>
                          <input
                            className='form-control'
                            type="number"
                            name="numberOfChildren"
                            onChange={handleChildrenForChange}
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <p>
                    Child Policy 👨‍👩‍👧‍👦<br />
                    ✅ Children below 5 years – Complimentary (No extra charges)<br />
                    ✅ Children aged 5 to 12 years – Special child rates apply<br />
                    ✅ Children above 12 years – Considered adults for pricing<br />

                    (Terms and conditions apply. Complimentary services may vary based on package inclusions.)
                  </p>
                </div>
              </div>
              <div className="day-plan-border" style={{ marginBlock: 15 }} />
              <div className='col-lg-9'>
                <div className="booking-date-outer d-flex">
                  <div className="booking-outer-2">
                    <h5 className="heading" style={{ fontSize: 16, marginBottom: 10 }}> Referral Code Details: </h5>
                    <div className="booking-choice d-flex">
                      <div >
                        <label style={{ marginBottom: 10 }}>Referral Code</label>
                        <input
                          className='form-control'
                          type="text"
                          name="refer"
                          onChange={handleReferChange}
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="day-plan-border" style={{ marginBlock: 15 }} />
              <div className="booking-outer-2">
                <h5 className="heading" style={{ fontSize: 16, marginBottom: 10 }}>
                  Contact Information
                </h5>
                <p className="sub-heading" style={{ marginBottom: 10 }}>
                  Booking details &amp; communication will be sent to -
                </p>
              </div>
              <div className="col-lg-4">
                <div className="booking-form">
                  <label htmlFor="name" style={{ marginBottom: 10 }}>  Name* </label>  <br />
                  <input
                    type="text"
                    className='form-control'
                    name="contact.name"
                    value={contact.name}
                    onChange={(e) => handleContactChange('name', e.target.value)}
                    placeholder="Enter Name"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="booking-form">
                  <label htmlFor="email" style={{ marginBottom: 10 }}>Email Id *</label><br />
                  <input
                    type="email"
                    id="email"
                    className='form-control'
                    required
                    name="contact.email"
                    value={contact.email}
                    onChange={(e) => handleContactChange('email', e.target.value)}
                    placeholder="Eg. john.doe@gmail.com"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="booking-form">
                  <label htmlFor="mobile" style={{ marginBottom: 10 }}>Mobile *</label> <br />
                  <input
                    type="text"
                    id="phoneno"
                    className='form-control'
                    maxLength={10}
                    required
                    name="contact.mobileNo"
                    value={contact.mobileNo}
                    onChange={(e) => handleContactChange('mobileNo', e.target.value)}
                    placeholder="+91 2584655896"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="booking-form">
                  <label htmlFor="city" style={{ marginBottom: 10 }}> City *</label><br />
                  <input
                    type="text"
                    id="city"
                    className='form-control'
                    required
                    name="contact.city"
                    value={contact.city}
                    onChange={(e) => handleContactChange('city', e.target.value)}
                    placeholder="Eg. New Delhi"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="booking-form">
                  <label htmlFor="state" style={{ marginBottom: 10 }}> State *     </label>    <br />
                  <input
                    type="text"
                    id="state"
                    className='form-control'
                    required
                    name="contact.state"
                    value={contact.state}
                    onChange={(e) => handleContactChange('state', e.target.value)}
                    placeholder="State"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="booking-form">
                  <label htmlFor="address" style={{ marginBottom: 10 }}>Address *</label>
                  <br />
                  <input type="text"
                    id="address-2"
                    required
                    className='form-control'
                    name="contact.address"
                    value={contact.address}
                    onChange={(e) => handleContactChange('address', e.target.value)}
                    placeholder="Eg. House Number, Street Name,..."
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="booking-outer-2">
                  <h5 className="heading" style={{ fontSize: "16px", marginBlock: "20px", paddingBlock: "15px", borderTop: "1px solid #ddd" }}
                  >
                    Booking Description:
                  </h5>

                  <div className='showPackages' >

                    <div className='showPackages' style={{ flexDirection: "column" }} dangerouslySetInnerHTML={createMarkup(pkg?.packageExclusions)} />

                  </div>
                </div>
              </div>
              <div className="day-plan-border" style={{ marginBlock: 15 }} />
              {/* cancellation policy */}
              <div className="traveller-details-outer d-flex">
                <div className="booking-outer-2">
                  <h5
                    className="heading"
                    style={{ fontSize: 16, marginBottom: 15, marginInlineEnd: 40 }}
                  >
                    Cancellation &amp; Date Change...
                  </h5>
                </div>
                <div className="change-btn Traveller-add-btn" style={{ marginBlock: 0 }}>
                  {/* <a href="#">Skip to Next</a> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="booking-outer-2">
                  <h5 className="heading" style={{ fontSize: 16, marginBottom: 5 }}>
                    Package Cancellation Policy
                  </h5>
                  <h5
                    className="heading"
                    style={{ fontSize: 16, marginBottom: 5, color: "#149797" }}
                  >
                    Cancellation Possible till {cancelDate}*
                  </h5>
                  <p className="sub-heading" style={{ marginBottom: 5 }}>
                    After that Package is Non - Refundable{" "}
                  </p>
                  <p className="sub-heading" style={{ marginBottom: 5 }}>
                    Package Cancellation Policy
                  </p>
                </div>
                <div className="details-border">
                  <div className="private-transfer-outer d-flex">
                    <div className="car-content">
                      <h3
                        className="sub-heading-2"
                        style={{ fontSize: 12, color: "#149797" }}
                      >
                        Till {cancelDate}
                      </h3>
                      <h3
                        className="sub-heading-2"
                        style={{ fontSize: 12, color: "#1B1E28" }}
                      >
                        ₹{(pkg?.discountPrice * pkg?.cancelPrice) / 100}
                      </h3>
                      <p className="sub-heading-2">Cancellation fee</p>
                      <h3
                        className="sub-heading-2"
                        style={{ fontSize: 12, color: "#FF3232" }}
                      >
                        After {cancelDate}
                      </h3>
                      <p className="sub-heading-2">
                        Non-Refundable <br />
                        Cancellation will not be allowed.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="refund-details">
                  <ul className="list-unstyled">
                    <li>
                      <div dangerouslySetInnerHTML={createMarkup(pkg?.policy)} />
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="col-lg-6">
                <div className="booking-outer-2">
                  <h5 className="heading" style={{ fontSize: 16, marginBottom: 5 }}>
                    Package Date Change Policy
                  </h5>
                  <h5
                    className="heading"
                    style={{ fontSize: 16, marginBottom: 5, color: "#149797" }}
                  >
                    Date Change Possible till {cancelDate}*
                  </h5>
                  <p className="sub-heading" style={{ marginBottom: 5 }}>
                    After that Package is Non - Refundable{" "}
                  </p>
                  <p className="sub-heading" style={{ marginBottom: 5 }}>
                    Package Date Change Policy
                  </p>
                </div>
                <div className="details-border">
                  <div className="private-transfer-outer d-flex">
                    <div className="car-content">
                      <h3
                        className="sub-heading-2"
                        style={{ fontSize: 12, color: "#149797" }}
                      >
                        Till {cancelDate}
                      </h3>
                      <h3
                        className="sub-heading-2"
                        style={{ fontSize: 12, color: "#1B1E28" }}
                      >
                        ₹{(pkg?.discountPrice * pkg?.datechangePrice) / 100}

                      </h3>
                      <p className="sub-heading-2">
                        Date Change Fee. Fare Difference will be extra.
                      </p>
                      <h3
                        className="sub-heading-2"
                        style={{ fontSize: 12, color: "#FF3232" }}
                      >
                        After {cancelDate}
                      </h3>
                      <p className="sub-heading-2">
                        Date change will <br /> not be allowed. <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="refund-details" >
                  <div dangerouslySetInnerHTML={createMarkup(pkg?.policy)} />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="important-information-outer">
                  <div className="booking-outer-2">
                    <h5 className="heading" style={{ fontSize: 16 }}>
                      Important Information
                    </h5>
                    <p className="sub-heading" style={{ marginBottom: 10 }}>
                      {" "}
                      By clicking the Continue button below to proceed with the booking, I confirm that I have read and accept the Cancellation Policy, User Agreement, Terms and Conditions of Service, and Privacy Policy of Winja Travels.{" "}
                    </p>
                  </div>
                  <div className="information-inner d-flex">
                    <div className="booking-outer-2">
                      <p
                        className="sub-heading"
                        style={{ marginBlock: 7, color: "#595959" }}
                      >
                        {" "}
                        Please complete your booking within the specified time. The package price will be refreshed after the timer expires.{" "}
                      </p>


                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="booking-outer-2 refund-details">
                  <h5 className="heading" style={{ fontSize: 16 }}>
                    Booking Process
                  </h5>
                  <h6 className="sub-heading" style={{ marginBottom: 10 }}>
                    ✅ Step 1: Registration Fee Submission
                  </h6>
                  <ul>
                    <li>To initiate your booking, a registration fee must be submitted.</li>
                    <li>If Step 2 is not completed, the registration fee will be refunded after deducting 20% as processing charges.</li>
                  </ul>
                  <h6 className="sub-heading" style={{ marginBottom: 10 }}>
                    ✅ Step 2: Final Payment
                  </h6>
                  <ul>
                    <li>
                      After completing Step 1, our team will review your booking details and contact you to confirm the remaining amount, based on the latest pricing and availability.
                    </li>
                    <li>
                      The final amount may increase or decrease depending on factors such as accommodations, travel dates, and additional inclusions.
                    </li>
                    <li>
                      📞 Our team will call you within 3-4 hours during our working hours (9:00 AM - 6:00 PM).
                    </li>
                    <li>
                      We will guide you through the next steps accordingly.
                    </li>
                  </ul>
                  <p>
                    📞 For any queries or assistance, contact us:<br />
                    📲Customer Care: 080-62179497 | 📲 Office: +91-7357501163
                  </p>
                </div>
              </div>
              {/* end cancelllation policy */}
              <div className="day-plan-border" style={{ marginBlock: 15 }} />
              <div className="book-now-outer">
                <div className="book-now-inner d-flex">
                  <div className="book-content">
                    <h3 className="heading" style={{ fontSize: 14 }}>
                      ₹<del> {pkg?.price} </del>/Per Person
                    </h3>
                    <h4 className="sub-heading-2" style={{ fontSize: 20, marginBlock: 8 }}>
                      Price: {pkg?.discountPrice}/Per Person
                    </h4>
                    <h4 className="sub-heading-2" style={{ fontSize: 20, marginBlock: 8 }}>
                      Total Person: {getFilledPassengerCount()}
                    </h4>
                    {/* <h4 className="sub-heading-2" style={{ fontSize: 20, marginBlock: 8 }}>
                      Total Amount: {pkg?.discountPrice * getFilledPassengerCount()} 
                    </h4> */}
                    {pkg.registrationPrice !== 0 && (
                      <h4 className="sub-heading-2" style={{ fontSize: 20, marginBlock: 8 }}>
                        Registration Fees: {pkg?.registrationPrice}
                      </h4>
                    )}
                    <h4 className="sub-heading-2" style={{ fontSize: 20, marginBlock: 8 }}>
                      Total Price: ₹
                      {(
                        pkg.discountPrice * getFilledPassengerCount() +
                        (pkg.discountPrice * getFilledPassengerCount() * (pkg.tax || 0) / 100)
                      ).toFixed(2)} {/* Display final amount */}
                    </h4>
                    <p>(Included Tax)</p>
                  </div>
                  <div className="book-now-btn">
                    <button onClick={handleBookingSubmit} className="btn btn-primary">
                      Pay Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Booking;