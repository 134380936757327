import React from 'react';

function Viewpkg({ pkg, closeModal }) {
  // console.log(">>>", pkg);
  if (!pkg) return null;

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return { date: formattedDate, time: formattedTime };
  }

  const { date, time } = formatDateTime(pkg.createdAt);

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  return (
    <div className="modal fade viewbox edit-box show d-block" id="manufacturerModal" tabIndex={-1} aria-labelledby="manufacturerModal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="manufacturerModal">Package Details</h5>
            <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
          </div>
          <div className="modal-body px-5">
            <div className="d-box mt-3 pt-1">
              <ul className="d-flex list-unstyled flex-wrap justify-content-between booking-details-2 first-ul">
                <li><span>Package Name: </span>{pkg?.packageName}</li>
                <li><span>Amount: </span>{pkg?.price}</li>
                <li><span>Offer Amount: </span>{pkg?.discountPrice}</li>
                <li><span>Tax: </span>{pkg?.tax}%</li>
                <li><span>Cancel Price: </span>{pkg?.cancelPrice}%</li>
                <li><span>Date Change Price: </span>{pkg?.datechangePrice}%</li>
                <li><span>Destination: </span>{pkg?.destination?.title}</li>
                <li><span>Start Location: </span>{pkg?.startLocation}</li>
                <li><span>End Location: </span>{pkg?.endLocation}</li>
                <li><span>Duration: </span>{pkg?.duration}</li>
                <li><span>Start Date: </span>{pkg?.startDate ? formatDateTime(pkg.startDate).date : 'N/A'}</li>
                <li><span>End Date: </span>{pkg?.endDate ? formatDateTime(pkg.endDate).date : 'N/A'}</li>
                <li><span>Cancel Date: </span>{pkg?.cancelDate ? formatDateTime(pkg.cancelDate).date : 'N/A'}</li>
                <li><span>Change Date: </span>{pkg?.changeDate ? formatDateTime(pkg.changeDate).date : 'N/A'}</li>
              </ul>
              <div className="d-box mt-3 pt-1">
                <ul className="d-flex list-unstyled flex-wrap justify-content-between booking-details-2 ">
                  <li><span>Package Inclusions </span>
                    <div dangerouslySetInnerHTML={createMarkup(pkg?.packageInclusions)} />
                  </li>
                </ul>
              </div>
              <div className="d-box mt-3 pt-1">
                <ul className="d-flex list-unstyled flex-wrap justify-content-between booking-details-2 ">
                  <li><span>Package Exclusion </span>
                    <div dangerouslySetInnerHTML={createMarkup(pkg?.packageExclusions)} />
                  </li>
                </ul>
              </div>

              <div className="d-box mt-3 pt-1">
                <ul className="d-flex list-unstyled flex-wrap justify-content-between booking-details-2 ">
                  <li><span>Package Policy </span>
                  <div dangerouslySetInnerHTML={createMarkup(pkg?.policy)} />
                  </li>
                </ul>
              </div>

              {/* Display images dynamically with a 4-column grid layout */}
              <div className="image-grid mt-5 d-box-grp">
                {pkg?.images?.map((image, index) => (
                  <a key={index} href={`${process.env.REACT_APP_URL}${image}`} target="_blank" rel="noopener noreferrer">
                    <img
                      src={image ? `${process.env.REACT_APP_URL}${image}` : 'img/profile-img.png'}
                      alt={`Package Image ${index + 1}`}
                      style={{ height: '200px', width: '200px', objectFit: 'contain', marginBottom: '20px' }}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Internal CSS */}
      <style>
        {`
          .image-grid {
            display: flex;
            flex-wrap: wrap;
            gap: 20px; /* Space between images */
          }

          .image-grid a {
            flex: 1 1 calc(25% - 20px); /* 4 images per row, taking into account the gap */
            max-width: calc(25% - 20px);
            text-align: center;
          }
        `}
      </style>
    </div>
  );
}

export default Viewpkg;
