// AdminLayout.js
import React from 'react';
import Header from '../component/Header/Header';
import Footer from '../component/Header/Footer';
import SideBar from '../component/Header/SideBar';
import { Outlet } from 'react-router-dom';


const AdminLayout = () => {
  return (
    <>
      <div className=" body">
      <div className="content">
      <Header />
      <SideBar />
      
        <Outlet /> {/* This will render nested routes */}
     
        <Footer />
      </div>
      </div>
    </>
  );
}



// const AdminLayout = ({ children }) => {
//   return (
//     <div className="admin-layout">
//       <Header />
//       <SideBar />
//       <div className="content">{children}</div>
//       <Footer />
//     </div>
//   );
// };

export default AdminLayout;
