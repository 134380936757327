import React, { useState } from 'react';
import useAsync from '../../hooks/useAsync';
import { Link } from 'react-router-dom';
import NotificationServices from '../../services/NotificationServices';
import Footer from './Common/footer';

function Notification() {
  const { data, error, isLoading } = useAsync(NotificationServices.getNotification);
  const [showAll, setShowAll] = useState(false); // State to toggle view

  // // Check for loading or error states
  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }

  // if (error) {
  //   return <p>Error loading notifications.</p>;
  // }

  // Assuming `data` contains an array of notifications
  const notifications = data?.data || [];

  // Limit to 5 notifications if `showAll` is false
  const displayedNotifications = showAll ? notifications : notifications.slice(0, 5);

  return (
    <>
      <section className="notification-section">
        <div className="container">
          <div className="tour-package-outer">
            <h3 className="banner-heading">Notification</h3>
          </div>
        </div>
      </section>

      <section className="all-package-section">
        <div className="container">
          <div className="breadcrumb-outer">
            <ul className="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Notification</li>
            </ul>
          </div>

          <div className="heading-outer d-flex">
            <div className="main-heading-outer">
              <h3 className="main-heading">Notification</h3>
            </div>
            {/* Show the "View All" button only if there are more than 5 notifications */}
            {notifications.length > 5 && (
              <div className="view-all-btn">
                <a href="#" onClick={() => setShowAll(!showAll)}>
                  {showAll ? 'View Less' : 'View More'}
                </a>
              </div>
            )}
          </div>

          <div className="notification-inner">
            {displayedNotifications.map((notification) => (
              <div key={notification._id} className="notifcation-outer">
                <div className="d-flex">
                  <div className="word-img">
                    {/* Dynamic Image */}
                    <a href={notification.linkUrl} target="_blank" rel="noopener noreferrer">
                      <img
                        src={notification?.image ? `${process.env.REACT_APP_URL}${notification.image}` : 'img/notification1.png'}
                        alt=""
                        style={{ height: '50px', width: '50px', objectFit: 'contain' }}
                      />
                    </a>
                  </div>
                  <div className="notification-content">
                    {/* Dynamic Title and Message */}
                    <h3 className="heading">{notification.title}</h3>
                    <p className="sub-heading" style={{ marginBottom: 15 }}>{notification.message}</p>
                  </div>
                </div>
                <div className="notification-content-2">
                  {/* Assuming you want to display the creation time */}
                  <p className="sub-heading" style={{ marginBottom: 15 }}>{new Date(notification.createdAt).toLocaleString()}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer component */}
      <Footer />
    </>
  );
}

export default Notification;
