import React, { useState, useEffect } from 'react';
import HomesliderServices from '../../services/HomeSliderServices';

function BannerUpdate({ banner, closeModal, onSuccess }) {
  const [formValues, setFormValues] = useState({
    title: '',
    image: '',
    sequence: '1',
    link: '',
    time: '',
    startDate: '',
    endDate: '',
  });

  const [previewImage, setPreviewImage] = useState('img/placeholder-img.png'); // Placeholder image path

  useEffect(() => {
    if (banner) {
      setFormValues({
        title: banner?.title || '',
        image: banner?.image || '',
        sequence: banner?.sequence || '1',
        link: banner?.link || '',
        time: banner?.time || '',
        startDate: banner?.startDate ? banner.startDate.split('T')[0] : '',
        endDate: banner?.endDate ? banner.endDate.split('T')[0] : '',
      });
      if (banner.image) {
        setPreviewImage(process.env.REACT_APP_URL + banner.image);
      }
    }
  }, [banner]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormValues({
        ...formValues,
        image: file,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      for (const key in formValues) {
        if (key !== 'image' || formValues[key] instanceof File) {
          formData.append(key, formValues[key]);
        }
      }

      await HomesliderServices.updateBanner(banner.id, formData);
      alert('Banner updated successfully');
      onSuccess();
      closeModal();
    } catch (error) {
      alert('Failed to update Banner');
    }
  };

  return (
    <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Banner Edit</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="container-box px-5">
              <div className="container-box-inner">
                <div className="page-details">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="input-field">
                          <label>Title*</label>
                          <input
                            type="text"
                            name="title"
                            className="form-control"
                            value={formValues.title}
                            onChange={handleInputChange}
                            placeholder="New banner"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input-field">
                          <label>Banner Sequence*</label>
                          <select
                            className="form-control"
                            value={formValues.sequence}
                            onChange={handleInputChange}
                            name="sequence"
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input-field">
                          <label>Offer Time</label>
                          <input
                            type="text"
                            name="time"
                            value={formValues.time}
                            onChange={handleInputChange}
                            placeholder="e.g., 24 hours"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input-field">
                          <label>Link*</label>
                          <div className="input-link-outer">
                            <input
                              type="text"
                              name="link"
                              value={formValues.link}
                              onChange={handleInputChange}
                              className="form-control"
                              placeholder="https://"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-field">
                          <label>Start Date</label>
                          <input
                            type="date"
                            name="startDate"
                            value={formValues.startDate}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-field">
                          <label>End Date</label>
                          <input
                            type="date"
                            name="endDate"
                            value={formValues.endDate}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-sm-9">
                        <div className="input-field">
                          <label>Upload<span className="red">*</span></label>
                          <input
                            type="file"
                            name="image"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                          <p>Supported formats: png, jpeg, jpg, webp</p>
                          <div className="file-preview text-center">
                            <img id="uploadFile" src={previewImage} alt="your image" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button className="site-btn-green">Update</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerUpdate;
