// src/RouteWrapper.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './component/FrontEnd/Common/header';
import Footer from './component/FrontEnd/Common/footer';

const RouteWrapper = ({ children }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');
  const isWebRoute = location.pathname.startsWith('/');

  return (
    <>
      {!isAdminRoute && !isWebRoute  && <Header />}
      <main>{children}</main>
      {!isAdminRoute && !isWebRoute  && <Footer />}
    </>
  );
};

export default RouteWrapper;